import { Injectable } from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

import {NotificationsService} from '../../../../projects/simple-notification/src/public_api';
import {$WebSocket, IAuth, WebSocketSendMode, IMessageRead, IWsSendData} from '../model';
import {SettingService} from './setting.service';
import {EmailTipService} from './email-tip.service';
import {environment} from '../../../environments/environment';
import {PushNotificationService} from './push-notification.service';
import {DbStorageService} from './db-storage.service';
import {PresentationLetterService} from './presentation-letter.service';

@Injectable({
  providedIn: 'root'
})
export class WsService {

  state: BehaviorSubject<string> = new BehaviorSubject<string>('init');

  tasks: {[key: string]: Subject<any>} = {};

  messageRead: BehaviorSubject<IMessageRead> = new BehaviorSubject(null);

  auth: BehaviorSubject<any> = new BehaviorSubject(null);

  private ws: $WebSocket;
  private user: IAuth;

  constructor(
    private pushNotification: PushNotificationService,
    public notifications: NotificationsService,
    public setting: SettingService,
    private dbStorage: DbStorageService,
    public emailTip: EmailTipService,
    public presentationLetter: PresentationLetterService
  ) { }

  init(user?: IAuth) {
    if (user && !this.ws) {
      this.user = user;
      this.wsInit(user.id);
    } else if (!user && this.ws) {
      this.ws.close(true);
      this.user = null;
    } else if (user && this.ws) {

    }
  }

  /**
   * Инициализация Socket
   */
  wsInit(userId: string) {

    this.ws = new $WebSocket(environment.socketUrl + userId + '&test=1', null, {
      initialTimeout: 500,
      maxTimeout: 300 * 1000,
      reconnectIfNotNormalClose: true
    });

    this.ws.setSend4Mode(WebSocketSendMode.Direct);

    this.ws.getDataStream().subscribe((res: MessageEvent) => {

      const isJsonRegex: any = /^(\x7b|\x5b).*(\x7d|\x5d)$/g;

      this.ws.setSend4Mode(WebSocketSendMode.Direct);

      if (res.type) {

        this.state.next(res.type);

        let data = res.data;

        if (typeof data === 'string') {
          if (isJsonRegex.test(data)) {
            data = JSON.parse(data);
          }
        }

        switch (res.type) {
          case 'message':
            this.goMessage(data);
            break;
          default:
            console.log(res.type); // @todo delete
            break;
        }
      }

    }, (error: any) => {
      console.log('Error::: ', error); // @todo delete
      this.notifications.warn(null, 'Ошибка соединения с сервером.<br /><small>code: ws.getDataStream</small>');
    }, () => {
      console.log('complete');
    });

    this.ws.onError((cb) => {
      this.state.next(cb.type);
      console.log('ws onError', cb);
      // this.notifications.warn(null, 'Ошибка соединения с сервером.<br /><small>code: ws.onError</small>');
    });

    this.ws.onOpen((cb) => {
      this.state.next(cb.type);
    });

    this.ws.onClose((cb) => {
      this.state.next(cb.type);
      console.log('ws onClose', cb);
    });
  }

  /**
   * Обработка ответа с сервера на запрос
   */
  goMessage(data: any) {

    if (data === 'ping') {
      this.ws.send('pong');
    } else if (data === 'not-fount') {

    } else if (data === 'ok') {
      this.userAuth();
    } else if (data.action) {
      this.toAction(data);
    } else if (data.cf) {

      // const key = data.cf;
      const key = `${data.cf}${data.key || ''}`;

      if (this.tasks[key]) {

        if (data.data) {
          this.tasks[key].next(data.data);
        } else {
          this.tasks[key].next(data);
        }

      } else {
        console.log('task: ', data);
      }
    }
  }

  toAction(item: any) {

    switch (item.action) {
      case 'messagesRead': // Клиент открыл сообщения.. emailId
        this.messageRead.next(item.data || null);
        if (this.setting.param.showNotifiAndMessageRead) {
          this.notifications.info(null, `Read message: №${item.data.emailId}`);
        }
        break;
      case 'option': // Получить настройки по компании
        this.presentationLetter.setTemplate(item.data.PRESENTATION_LETTER || '');
        break;
    }
  }

  /**
   * Отправляем данные на сервер
   *
   * @param {IWsSendData} data
   * @param {boolean} isPromise
   * @returns {Promise<any>}
   */
  toSend(data: IWsSendData, isPromise?: boolean): Promise<any> {

    data.key = Math.random();
    const taskKey = data.cf + data.key;

    if (isPromise) {
      this.tasks[taskKey] = new Subject<any>();
    }

    this.ws.send(data);

    if (isPromise) {
      return new Promise((resolve, reject) => {
        this.tasks[taskKey].subscribe((res: any) => {
          if (res.errors) {
            reject(res.errors);
          } else {
            resolve(res);
          }
        });
      });
    }
  }

  /**
   * Авторизация на сервере
   */
  private userAuth() {
    this.toSend({
      cf: 'user.auth',
      data: this.user
    }, true).then((user: any) => {

      if (user && user.id && user.id > 0) {

        this.auth.next(user);
        this.setting.auth = user;

        if (user.maxResults) {
          this.setting.param.maxResults = user.maxResults;
        }
        if (user.language) {
          this.setting.param.language = user.language;
        }
        /**
         * Если включены браузерние уведомления то
         * проверяем наличия доступа
         */
        if (user.pushNotification) {
          this.pushNotification.requestPermission();
        }

        this.setting.param.showNotifiAndMessageRead = user.showNotifiAndMessageRead || false;
        this.setting.param.includeSpamTrash = user.includeSpamTrash || false;
        this.setting.param.conversations = user.conversations || false;
        this.setting.param.isAdmin = user.isAdmin || false;
        this.setting.param.signature = user.signature || '';

        this.initEmailTip();
      } else {
        this.auth.next(false);
        this.setting.auth = null;
      }
    }).catch((error: any) => {
      if (error && error.message) {
        switch (error.message) {
          case 'Email is not allowed':
            this.notifications.warn(null, `Email (${this.user.email}) для авторизации запрещен`);
            break;
          default:
            if (typeof error.message === 'string') {
              this.notifications.warn(null, error.message);
            }
            break;
        }
      } else {
        this.notifications.warn(null, `Ошибка авторизации`);
      }

      this.auth.next(false);
      this.setting.auth = null;
    });
  }

  /**
   * Получить список emails для подсказок
   */
  private initEmailTip() {

    this.dbStorage.getTable('settings').subscribe((db) => {
      /**
       * Проверяем когда последний раз загружалить контакты
       */
      db.get('lastUpdateContacts').subscribe((lastUpdateContacts: any) => {

        const diffTime = this.dbStorage.diffTime((lastUpdateContacts ? lastUpdateContacts.value : Date.now()));
        /**
         * Если время после последнего замера больше
         *    то синхронизируем контакты
         */
        if (!lastUpdateContacts  || diffTime > this.emailTip.contacts.timeUpdate) {
          this.toSend({
            cf: 'contacts.list',
            data: this.emailTip.contacts.getGA()
          }, true).then((items: any) => {
            if (items) {
              this.emailTip.contacts.load(items);
            }
          }).catch((error: any) => {
            console.log(error);
          });
        } else {
          this.dbStorage.getTable('contacts').subscribe((db) => { // tslint:disable-line

            db.all().subscribe((items: any[]) => {
              if (items) {

                this.emailTip.contacts.load(items.map((v: any) => {
                  return {
                    email: v.id,
                    name: v.value
                  };
                }));
              }
            });
          });
        }
      });
    });

    this.toSend({
      cf: 'emailTip.index',
      data: this.user.id
    }, true).then((items: any) => {
      this.emailTip.set(items);
    }).catch((error: any) => {
      console.log(error);
    });

  }

  /**
   * Сохраняем emails для подсказок
   * @param {string[]} emails
   */
  addEmailTip(emails: string[]) {

    emails = emails.map((e: string) => e.toLowerCase()).filter((e: string) => !this.emailTip.has(e));

    if (emails && emails.length) {

      this.emailTip.set(emails);

      this.ws.send({
        cf: 'emailTip.create',
        data: {
          userId: this.user.id,
          emails: emails
        }
      });
    }
  }
}
