import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}


declare var bootHandleClientLoad: any;
/**
 * https://developers.google.com/api-client-library/javascript/samples/samples
 */
(window as any).bootHandleClientLoad = () => {};

if (!environment.production) {
  // console.log('bootHandleClientLoad');
  // console.log(navigator);
  // console.log('language: ', navigator.language);
}
// uk-UA
if (window.navigator.language !== 'en') {}

/**
 * Если в браузере отключены куки то выводимсообщения
 * https://developer.mozilla.org/en-US/docs/Web/API/Navigator/cookieEnabled
 */
if (!navigator.cookieEnabled) {
  alert( 'The browser does not support or block cookies.\nEnable cookies to work with the site' );
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .then(() => {})
  .catch(err => {
    console.log('platformBrowserDynamic: ', err);
  });
