import { GApiService } from '../service';
import * as i0 from "@angular/core";
import * as i1 from "../service/g-api.service";
export class GapiCanGuard {
    constructor(gApi) {
        this.gApi = gApi;
    }
    /**
     * Проверяем прошла ли инициализация GAPI
     */
    canLoad(route) {
        return this.gApi.init().then((status) => {
            return status;
        });
    }
    canActivate(route, state) {
        return this.canLoad(null);
    }
}
GapiCanGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GapiCanGuard_Factory() { return new GapiCanGuard(i0.ɵɵinject(i1.GApiService)); }, token: GapiCanGuard, providedIn: "root" });
