import { BehaviorSubject, Subject } from 'rxjs';
import { NotificationsService } from '../../../../projects/simple-notification/src/public_api';
import { $WebSocket, WebSocketSendMode } from '../model';
import { SettingService } from './setting.service';
import { EmailTipService } from './email-tip.service';
import { environment } from '../../../environments/environment';
import { PushNotificationService } from './push-notification.service';
import { DbStorageService } from './db-storage.service';
import { PresentationLetterService } from './presentation-letter.service';
import * as i0 from "@angular/core";
import * as i1 from "./push-notification.service";
import * as i2 from "../../../../projects/simple-notification/src/lib/services/notifications.service";
import * as i3 from "./setting.service";
import * as i4 from "./db-storage.service";
import * as i5 from "./email-tip.service";
import * as i6 from "./presentation-letter.service";
export class WsService {
    constructor(pushNotification, notifications, setting, dbStorage, emailTip, presentationLetter) {
        this.pushNotification = pushNotification;
        this.notifications = notifications;
        this.setting = setting;
        this.dbStorage = dbStorage;
        this.emailTip = emailTip;
        this.presentationLetter = presentationLetter;
        this.state = new BehaviorSubject('init');
        this.tasks = {};
        this.messageRead = new BehaviorSubject(null);
        this.auth = new BehaviorSubject(null);
    }
    init(user) {
        if (user && !this.ws) {
            this.user = user;
            this.wsInit(user.id);
        }
        else if (!user && this.ws) {
            this.ws.close(true);
            this.user = null;
        }
        else if (user && this.ws) {
        }
    }
    /**
     * Инициализация Socket
     */
    wsInit(userId) {
        this.ws = new $WebSocket(environment.socketUrl + userId + '&test=1', null, {
            initialTimeout: 500,
            maxTimeout: 300 * 1000,
            reconnectIfNotNormalClose: true
        });
        this.ws.setSend4Mode(WebSocketSendMode.Direct);
        this.ws.getDataStream().subscribe((res) => {
            const isJsonRegex = /^(\x7b|\x5b).*(\x7d|\x5d)$/g;
            this.ws.setSend4Mode(WebSocketSendMode.Direct);
            if (res.type) {
                this.state.next(res.type);
                let data = res.data;
                if (typeof data === 'string') {
                    if (isJsonRegex.test(data)) {
                        data = JSON.parse(data);
                    }
                }
                switch (res.type) {
                    case 'message':
                        this.goMessage(data);
                        break;
                    default:
                        console.log(res.type); // @todo delete
                        break;
                }
            }
        }, (error) => {
            console.log('Error::: ', error); // @todo delete
            this.notifications.warn(null, 'Ошибка соединения с сервером.<br /><small>code: ws.getDataStream</small>');
        }, () => {
            console.log('complete');
        });
        this.ws.onError((cb) => {
            this.state.next(cb.type);
            console.log('ws onError', cb);
            // this.notifications.warn(null, 'Ошибка соединения с сервером.<br /><small>code: ws.onError</small>');
        });
        this.ws.onOpen((cb) => {
            this.state.next(cb.type);
        });
        this.ws.onClose((cb) => {
            this.state.next(cb.type);
            console.log('ws onClose', cb);
        });
    }
    /**
     * Обработка ответа с сервера на запрос
     */
    goMessage(data) {
        if (data === 'ping') {
            this.ws.send('pong');
        }
        else if (data === 'not-fount') {
        }
        else if (data === 'ok') {
            this.userAuth();
        }
        else if (data.action) {
            this.toAction(data);
        }
        else if (data.cf) {
            // const key = data.cf;
            const key = `${data.cf}${data.key || ''}`;
            if (this.tasks[key]) {
                if (data.data) {
                    this.tasks[key].next(data.data);
                }
                else {
                    this.tasks[key].next(data);
                }
            }
            else {
                console.log('task: ', data);
            }
        }
    }
    toAction(item) {
        switch (item.action) {
            case 'messagesRead': // Клиент открыл сообщения.. emailId
                this.messageRead.next(item.data || null);
                if (this.setting.param.showNotifiAndMessageRead) {
                    this.notifications.info(null, `Read message: №${item.data.emailId}`);
                }
                break;
            case 'option': // Получить настройки по компании
                this.presentationLetter.setTemplate(item.data.PRESENTATION_LETTER || '');
                break;
        }
    }
    /**
     * Отправляем данные на сервер
     *
     * @param {IWsSendData} data
     * @param {boolean} isPromise
     * @returns {Promise<any>}
     */
    toSend(data, isPromise) {
        data.key = Math.random();
        const taskKey = data.cf + data.key;
        if (isPromise) {
            this.tasks[taskKey] = new Subject();
        }
        this.ws.send(data);
        if (isPromise) {
            return new Promise((resolve, reject) => {
                this.tasks[taskKey].subscribe((res) => {
                    if (res.errors) {
                        reject(res.errors);
                    }
                    else {
                        resolve(res);
                    }
                });
            });
        }
    }
    /**
     * Авторизация на сервере
     */
    userAuth() {
        this.toSend({
            cf: 'user.auth',
            data: this.user
        }, true).then((user) => {
            if (user && user.id && user.id > 0) {
                this.auth.next(user);
                this.setting.auth = user;
                if (user.maxResults) {
                    this.setting.param.maxResults = user.maxResults;
                }
                if (user.language) {
                    this.setting.param.language = user.language;
                }
                /**
                 * Если включены браузерние уведомления то
                 * проверяем наличия доступа
                 */
                if (user.pushNotification) {
                    this.pushNotification.requestPermission();
                }
                this.setting.param.showNotifiAndMessageRead = user.showNotifiAndMessageRead || false;
                this.setting.param.includeSpamTrash = user.includeSpamTrash || false;
                this.setting.param.conversations = user.conversations || false;
                this.setting.param.isAdmin = user.isAdmin || false;
                this.setting.param.signature = user.signature || '';
                this.initEmailTip();
            }
            else {
                this.auth.next(false);
                this.setting.auth = null;
            }
        }).catch((error) => {
            if (error && error.message) {
                switch (error.message) {
                    case 'Email is not allowed':
                        this.notifications.warn(null, `Email (${this.user.email}) для авторизации запрещен`);
                        break;
                    default:
                        if (typeof error.message === 'string') {
                            this.notifications.warn(null, error.message);
                        }
                        break;
                }
            }
            else {
                this.notifications.warn(null, `Ошибка авторизации`);
            }
            this.auth.next(false);
            this.setting.auth = null;
        });
    }
    /**
     * Получить список emails для подсказок
     */
    initEmailTip() {
        this.dbStorage.getTable('settings').subscribe((db) => {
            /**
             * Проверяем когда последний раз загружалить контакты
             */
            db.get('lastUpdateContacts').subscribe((lastUpdateContacts) => {
                const diffTime = this.dbStorage.diffTime((lastUpdateContacts ? lastUpdateContacts.value : Date.now()));
                /**
                 * Если время после последнего замера больше
                 *    то синхронизируем контакты
                 */
                if (!lastUpdateContacts || diffTime > this.emailTip.contacts.timeUpdate) {
                    this.toSend({
                        cf: 'contacts.list',
                        data: this.emailTip.contacts.getGA()
                    }, true).then((items) => {
                        if (items) {
                            this.emailTip.contacts.load(items);
                        }
                    }).catch((error) => {
                        console.log(error);
                    });
                }
                else {
                    this.dbStorage.getTable('contacts').subscribe((db) => {
                        db.all().subscribe((items) => {
                            if (items) {
                                this.emailTip.contacts.load(items.map((v) => {
                                    return {
                                        email: v.id,
                                        name: v.value
                                    };
                                }));
                            }
                        });
                    });
                }
            });
        });
        this.toSend({
            cf: 'emailTip.index',
            data: this.user.id
        }, true).then((items) => {
            this.emailTip.set(items);
        }).catch((error) => {
            console.log(error);
        });
    }
    /**
     * Сохраняем emails для подсказок
     * @param {string[]} emails
     */
    addEmailTip(emails) {
        emails = emails.map((e) => e.toLowerCase()).filter((e) => !this.emailTip.has(e));
        if (emails && emails.length) {
            this.emailTip.set(emails);
            this.ws.send({
                cf: 'emailTip.create',
                data: {
                    userId: this.user.id,
                    emails: emails
                }
            });
        }
    }
}
WsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WsService_Factory() { return new WsService(i0.ɵɵinject(i1.PushNotificationService), i0.ɵɵinject(i2.NotificationsService), i0.ɵɵinject(i3.SettingService), i0.ɵɵinject(i4.DbStorageService), i0.ɵɵinject(i5.EmailTipService), i0.ɵɵinject(i6.PresentationLetterService)); }, token: WsService, providedIn: "root" });
