export class Auth implements IAuth {

  constructor(
    public id,
    public email,
    public familyName,
    public givenName,
    public name,
    public imageUrl,
  ) {}
}
export interface IAuth {
  id: any;
  email: any;
  familyName: any;
  givenName: any;
  /**
   * @deprecated
   */
  name: any;
  imageUrl: any;
}
