export class SettingModel implements ISetting {
  /**
   * Максимальное количество сообщений для возврата.
   */
  maxResults = 15;
  /**
   * Язык интерфейса
   */
  language = 'ru';
  /**
   * Вывести уведомления когда клиент открыл сообщения
   */
  showNotifiAndMessageRead = true;
  /**
   * Включите сообщения из SPAM и TRASH в результаты.
   * @type {boolean}
   */
  includeSpamTrash = false;

  conversations = false;

  isAdmin = false;

  signature = '';
}

export interface ISetting {
  /**
   * Максимальное количество сообщений для возврата.
   */
  language: string;
  /**
   * Язык интерфейса
   */
  maxResults: number;
  /**
   * Вывести уведомления когда клиент открыл сообщения
   */
  showNotifiAndMessageRead: boolean;
  /**
   * Включите сообщения из SPAM и TRASH в результаты.
   * @type {boolean}
   */
  includeSpamTrash: boolean;

  conversations: boolean;

  isAdmin: boolean;

  signature: string;
}

/**
 * Параметры, связанные с псевдонимом send-as
 *
 * @link https://developers.google.com/gmail/api/v1/reference/users/settings/sendAs#resource
 */
export interface ISettingGmail {
  sendAsEmail: string;
  displayName: string;
  replyToAddress: string;
  signature: string;
  isPrimary: boolean;
  isDefault: boolean;
  treatAsAlias: boolean;
  smtpMsa?: ISettingGmailSmtpMsa;
  verificationStatus?: 'accepted' | 'pending' | 'verificationStatusUnspecified';
}
export interface ISettingGmailSmtpMsa {
  host: string;
  port: number;
  username: string;
  password: string;
  securityMode: string;
}
