import { from, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
/**
 * Локальная DB
 */
export class DbStorageService {
    constructor() { }
    diffTime(time) {
        if (!time) {
            return 0;
        }
        return Math.abs(Math.ceil((time - Date.now()) / 1000));
    }
    getTable(table) {
        let db;
        if ('indexedDB' in window) {
            db = new IndexedDBStorage();
        }
        else {
            db = new MemoryStorage();
        }
        return db.table(table);
    }
}
DbStorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DbStorageService_Factory() { return new DbStorageService(); }, token: DbStorageService, providedIn: "root" });
class IndexedDBStorage {
    constructor(databaseName = 'ngDatabaseName') {
        this.databaseName = databaseName;
    }
    getDb(version, storeName) {
        return Observable.create((observer) => {
            const req = version && version > 0 ? window.indexedDB.open(this.databaseName, version)
                : window.indexedDB.open(this.databaseName);
            req.onsuccess = (event) => {
                const db = event.target.result;
                observer.next(db);
                db.close();
                observer.complete();
            };
            req.onupgradeneeded = (e) => {
                const db = e.target.result;
                if (storeName && !db.objectStoreNames.contains(storeName)) {
                    db.createObjectStore(storeName, {
                        keyPath: 'id'
                    });
                    const transaction = e.target.transaction;
                    transaction.oncomplete = (event) => {
                        observer.next(db);
                        db.close();
                        observer.complete();
                    };
                }
            };
            req.onblocked = (event) => observer.error('IndexedDB is blocked');
            req.onerror = (e) => observer.error(e.error);
        });
    }
    getVersionOfDb(name) {
        return this.getDb().pipe(map(db => {
            if (!db.objectStoreNames.contains(this.name)) {
                return db.version + 1;
            }
            else {
                return db.version;
            }
        }));
    }
    table(name) {
        this.name = name;
        return Observable.create((observer) => {
            this.getVersionOfDb(name).subscribe((version) => {
                this.getDb(version, name).subscribe(db => {
                    observer.next(this);
                    observer.complete();
                });
            });
        });
    }
    all() {
        return Observable.create((observer) => {
            this.getDb().subscribe(db => {
                const result = [];
                const req = db.transaction(this.name, 'readonly').objectStore(this.name).openCursor();
                req.onsuccess = (event) => {
                    const res = event.target.result;
                    if (res) {
                        result.push(res.value);
                        res.continue();
                    }
                    else {
                        observer.next(result);
                        observer.complete();
                    }
                };
                req.onerror = (e) => observer.error(e.error);
            });
        });
    }
    get(key) {
        key = (typeof key === 'number') ? key.toString() : key;
        return Observable.create((observer) => {
            this.getDb().subscribe(db => {
                const req = db.transaction(this.name, 'readonly').objectStore(this.name).get(key);
                req.onerror = (e) => observer.error(e.error);
                req.onsuccess = (e) => {
                    observer.next(req.result);
                    observer.complete();
                };
            });
        });
    }
    clear() {
        return Observable.create((observer) => {
            this.getDb().subscribe(db => {
                const req = db.transaction(this.name, 'readwrite').objectStore(this.name).clear();
                req.onerror = (e) => observer.error(e.error);
                req.onsuccess = (e) => {
                    observer.next(this);
                    observer.complete();
                };
            });
        });
    }
    put(value) {
        if (!value.id) {
            value.id = Math.random().toString(36).substring(7);
        }
        else {
            value.id = (typeof value.id === 'number') ? value.id.toString() : value.id;
        }
        return Observable.create((observer) => {
            this.getDb().subscribe(db => {
                const req = db.transaction(this.name, 'readwrite')
                    .objectStore(this.name)
                    .put(value);
                req.onerror = (e) => {
                    observer.error(e.error);
                };
                req.onsuccess = (e) => {
                    observer.next(value);
                    observer.complete();
                };
            });
        });
    }
    getDenseBatch(keys) {
        return Observable.create((observer) => {
            this.getDb().subscribe(db => {
                const set = keys.sort();
                let i = 0;
                const req = db.transaction(this.name).objectStore(this.name)
                    .openCursor();
                req.onsuccess = (event) => {
                    // let cursor = (<any>event.target).result;
                    const cursor = event.target.result;
                    if (!cursor) {
                        observer.complete();
                        return;
                    }
                    const key = cursor.key;
                    while (key > set[i]) {
                        // The cursor has passed beyond this key. Check next.
                        ++i;
                        if (i === set.length) {
                            // There is no next. Stop searching.
                            observer.complete();
                            return;
                        }
                    }
                    if (key === set[i]) {
                        // The current cursor value should be included and we should continue
                        // a single step in case next item has the same key or possibly our
                        // next key in set.
                        // observer.next(cursor.value);
                        observer.next(cursor);
                        cursor.continue();
                    }
                    else {
                        // cursor.key not yet at set[i]. Forward cursor to the next key to hunt for.
                        cursor.continue(set[i]);
                    }
                };
                req.onerror = (e) => observer.error(e.error);
            });
        });
    }
}
class MemoryStorage {
    constructor() {
        this.storage = {};
    }
    table(name) {
        return of(this);
    }
    get(key) {
        // return Observable.of(this.storage[key]);
        key = (typeof key === 'number') ? key.toString() : key;
        return of(this.storage[key]);
    }
    clear() {
        this.storage = {};
        // return Observable.empty<any>();
        return of();
    }
    put(value) {
        if (!value.id) {
            value.id = Math.random().toString(36).substring(7);
        }
        this.storage[value.id] = value;
        return of(value);
    }
    getDenseBatch(keys) {
        return from(keys.map(x => this.storage[x]));
    }
    all() {
        return from(Object.keys(this.storage).map(x => this.storage[x]));
    }
}
