import {CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SimpleNotificationsComponent} from './components/simple-notifications/simple-notifications.component';
import {DEFAULT_OPTIONS} from './consts/default-options.const';
import {Options} from './interfaces/options.type';
import {NotificationComponent} from './components/notification/notification.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    SimpleNotificationsComponent,
    NotificationComponent
  ],
  entryComponents: [
    SimpleNotificationsComponent,
    NotificationComponent
  ],
  exports: [
    SimpleNotificationsComponent,
    NotificationComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class SimpleNotificationModule {
  static withConfig(userConfig: Options = {}): ModuleWithProviders {
    return {
      ngModule: SimpleNotificationModule,
      providers: [
        {provide: 'NOTIFICATION_OPTION', useValue: Object.assign(DEFAULT_OPTIONS, userConfig)}
      ]
    };
  }

}
