/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./simple-notifications.component.css.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../notification/notification.component.ngfactory";
import * as i3 from "../notification/notification.component";
import * as i4 from "../../services/notifications.service";
import * as i5 from "@angular/platform-browser";
import * as i6 from "@angular/common";
import * as i7 from "./simple-notifications.component";
var styles_SimpleNotificationsComponent = [i0.styles];
var RenderType_SimpleNotificationsComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_SimpleNotificationsComponent, data: {} });
export { RenderType_SimpleNotificationsComponent as RenderType_SimpleNotificationsComponent };
function View_SimpleNotificationsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "simple-notification", [], null, null, null, i2.View_NotificationComponent_0, i2.RenderType_NotificationComponent)), i1.ɵdid(1, 245760, null, 0, i3.NotificationComponent, [i4.NotificationsService, i5.DomSanitizer, i1.ChangeDetectorRef, i1.NgZone], { timeOut: [0, "timeOut"], showProgressBar: [1, "showProgressBar"], pauseOnHover: [2, "pauseOnHover"], clickToClose: [3, "clickToClose"], clickIconToClose: [4, "clickIconToClose"], maxLength: [5, "maxLength"], theClass: [6, "theClass"], rtl: [7, "rtl"], animate: [8, "animate"], position: [9, "position"], item: [10, "item"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.timeOut; var currVal_1 = _co.showProgressBar; var currVal_2 = _co.pauseOnHover; var currVal_3 = _co.clickToClose; var currVal_4 = _co.clickIconToClose; var currVal_5 = _co.maxLength; var currVal_6 = _co.theClass; var currVal_7 = _co.rtl; var currVal_8 = _co.animate; var currVal_9 = _v.context.index; var currVal_10 = _v.context.$implicit; _ck(_v, 1, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10]); }, null); }
export function View_SimpleNotificationsComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "simple-notification-wrapper"]], null, null, null, null, null)), i1.ɵprd(512, null, i6.ɵNgClassImpl, i6.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i6.NgClass, [i6.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SimpleNotificationsComponent_1)), i1.ɵdid(4, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "simple-notification-wrapper"; var currVal_1 = _co.position; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.notifications; _ck(_v, 4, 0, currVal_2); }, null); }
export function View_SimpleNotificationsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "simple-notifications", [], null, null, null, View_SimpleNotificationsComponent_0, RenderType_SimpleNotificationsComponent)), i1.ɵdid(1, 245760, null, 0, i7.SimpleNotificationsComponent, [i4.NotificationsService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SimpleNotificationsComponentNgFactory = i1.ɵccf("simple-notifications", i7.SimpleNotificationsComponent, View_SimpleNotificationsComponent_Host_0, { options: "options" }, { onCreate: "onCreate", onDestroy: "onDestroy" }, []);
export { SimpleNotificationsComponentNgFactory as SimpleNotificationsComponentNgFactory };
