/* tslint:disable */
import {environment} from '../environments/environment';

export class AppConfig {
  static metaTitle = 'Binotel Email Maker';
  /**
   * Максимальный размер файла для загрузки
   * 20 MB
   */
  static maxSizeUpload = 2e+7;
  static maxSizeAttachment = 2e+7;
  static iFrameMailJs = '/assets/mail.js';
  static iFrameMailCss = '/assets/mail.css';
  /**
   * Идентификатор клиента приложения, созданный в Google Developers Console.
   */
  static clientId = '716734684107-boi7cgk8mmnfh68u4ff32sppru9k0ob4.apps.googleusercontent.com';

  static apiKey = 'AIzaSyCdOQyp9YSLm7R9oUGvTbIyIQoGrfs-Tow';
  /**
   * Домен G Suite, к которому должны принадлежать пользователи для входа.
   */
  static hostedDomain = environment.production ? 'binotel.com' : '';

  static discoveryDocs = [
    'https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest'
  ];
  /**
   * https://developers.google.com/identity/protocols/googlescopes
   */
  static scope = [
    'https://mail.google.com/',
    'https://www.googleapis.com/auth/gmail.readonly',
    'https://www.googleapis.com/auth/gmail.compose',
    'https://www.googleapis.com/auth/gmail.send',
    'https://www.googleapis.com/auth/gmail.insert',
    'https://www.googleapis.com/auth/gmail.labels',
    'https://www.googleapis.com/auth/gmail.modify',
    'https://www.googleapis.com/auth/gmail.settings.basic',
    'https://www.googleapis.com/auth/gmail.settings.sharing',
    'https://www.googleapis.com/auth/userinfo.profile',

    'https://www.googleapis.com/auth/contacts',
    'https://www.googleapis.com/auth/contacts.readonly',
    // 'https://www.googleapis.com/auth/user.addresses.read',
    // 'https://www.googleapis.com/auth/user.phonenumbers.read',
    'https://www.googleapis.com/auth/user.emails.read',
    'https://www.googleapis.com/auth/userinfo.email',

    'https://www.googleapis.com/auth/groups',
    // 'https://www.googleapis.com/auth/user.birthday.read',

    'email',
    'profile',
    'openid'
  ].join(' ');

  static templateEditorOption = {
    language: 'ru',
    theme: 'template',
    heightMin: 100,
    toolbarStickyOffset: 0,
    scrollableContainer: '.editor',
    iframeStyleFiles: ['/assets/mail.css'],
    placeholderText: 'Введите текст...',
    // initOnClick: true,
    imagePasteProcess: true,
    toolbarButtons: [
      'bold', '|',
      'italic', '|',
      'underline', '|',
      'strikeThrough', '|',
      'fontFamily', '|',
      'fontSize', '|',
      'color', '|',
      'inlineStyle', '|',
      'align', '|',
      'formatOL', '|',
      'formatUL', '|',
      // 'outdent', '|',
      // 'indent', '|',
      'insertLink', '|',
      'insertImage', '|',
      'insertTable', '|',
      // 'insertHR', '|',
      'clearFormatting',  '|',
      // 'fullscreen',  '|',
      'lineHeight',  '|',
      'paragraphFormat',  '|',
      'extend_toolbar_buttons_dropdown',
      // 'spellChecker',
      'html'
    ],
  };

  static profileEditorOption = {
    language: 'ru',
    theme: 'profile',
    heightMin: 100,
    scrollableContainer: '.ng-editor',
    iframeStyleFiles: ['/assets/mail.css'],
    placeholderText: 'Введите текст подписи...',
    toolbarButtons: [
      'bold', '|',
      'italic', '|',
      'underline', '|',
      'strikeThrough', '|',
      'fontFamily', '|',
      'fontSize', '|',
      'color', '|',
      'inlineStyle', '|',
      'align', '|',
      'formatOL', '|',
      'formatUL', '|',
      'insertLink', '|',
      'insertImage', '|',
      'insertTable', '|',
      'insertHR', '|',
      'paragraphFormat',
      'extend_toolbar_buttons_dropdown',
      'html'
    ],
  };

  static emailEditorOption = {
    fullPage: true,
    language: 'ru',
    theme: 'email-message',
    toolbarBottom: true,
    toolbarStickyOffset: 0,
    heightMin: 150,
    scrollableContainer: '.mail-content-block', // 'mail-message', // '.perfect-scrollbar',
    iframeStyleFiles: ['/assets/mail.css'],
    placeholderText: 'Введите текст письма...',
    /* Список кнопок, которые отображаются на панели инструментов редактора */
    toolbarButtons: [
      'presentationLetterTemplate', '|',

      'bold', '|',
      'italic', '|',
      'underline', '|',
      'strikeThrough', '|',
      'fontFamily', '|',
      'fontSize', '|',
      'color', '|',
      'inlineStyle', '|',
      'align', '|',
      'formatOL', '|',
      'formatUL', '|',
      // 'outdent', '|',
      // 'indent', '|',
      'insertLink', '|',
      'insertImage', '|',
      'insertTable', '|',
      // 'insertHR', '|',
      'lineHeight',  '|',
      'paragraphFormat', '|',
      // 'clearFormatting'
      'extend_toolbar_buttons_dropdown',
    ],
    toolbarButtonsMD: [
      'presentationLetterTemplate', '|',

      'bold', '|',
      'italic', '|',
      'underline', '|',
      'strikeThrough', '|',
      'fontFamily', '|',
      'fontSize', '|',
      'color', '|',
      'inlineStyle', '|',
      'align', '|',
      'formatOL', '-',
      'formatUL', '|',
      // 'outdent', '|',
      // 'indent', '|',
      'insertLink', '|',
      'insertImage', '|',
      'insertTable', '|',
      // 'insertHR', '|',
      'lineHeight',  '|',
      'paragraphFormat', '|',
      // 'clearFormatting'
      'extend_toolbar_buttons_dropdown',
    ],
  };

  static froalaEditorOptions: any = {
    language: 'ru',
    key: (environment.production && !environment.local) ? '3A11A5C4A4gC3E3A2D3B7D5A3F4C2G3zeageE-13iaH5lC-7G-7==' : 'localhost'
  };

  static actionUrl(patch: string): string {
    return environment.actionUrl + patch;
  }
}
/**
 * customize css
 * https://www.froala.com/wysiwyg-editor/customize
 */
(window as any).$.FroalaEditor.DEFAULTS = $.extend((window as any).$.FroalaEditor.DEFAULTS, {
  language: 'ru',
  enter: 1, // FE.ENTER_P=0 | FE.ENTER_DIV=1 | FE.ENTER_BR=2
  zIndex: 0,
  tabSpaces: 4,
  heightMin: 150,
  charCounterCount: false,
  spellcheck: true,
  fileUpload: false,
  emoticonsUseImage: false,
  /* Указывает, должен ли добавляться атрибут rel="nofollow" во всех ссылках. */
  linkAlwaysNoFollow: false,
  /* Показать вход HEX, чтобы выбрать пользовательский цвет. */
  colorsHEXInput: true,
  /* Позволяет вставлять новую строку при нажатии клавиши ENTER. */
  multiLine: true,
  /**
   * Указывает, как перетаскиваемые элементы должны быть размещены в новой позиции.
   * Когда эта опция отключена, перетаскиваемые элементы помещаются между блочными тегами, а не внутри них.
   */
  dragInline: false,
  lineHeights: {
    '1': '1',
    '1.1': '1.1',
    '1.2': '1.2',
    '1.3': '1.3',
    '1.4': '1.4',
    '1.5': '1.5'
  },
  paragraphFormat: {
    N: 'Normal',
    H1: 'Heading 1',
    H2: 'Heading 2',
    H3: 'Heading 3',
    H4: 'Heading 4',
    P: 'Paragraph',
    PRE: 'Code'
  },
  /* Список кнопок, которые отображаются на панели инструментов редактора */
  toolbarButtons: [
    'paste', '|',
    'bold', '|',
    'italic', '|',
    'underline', '|',
    'strikeThrough', '|',
    'fontFamily', '|',
    'fontSize', '|',
    'color', '|',
    'inlineStyle', '|',
    'align', '|',
    'formatOL', '|',
    'formatUL', '|',
    'outdent', '|',
    'indent', '|',
    'insertLink', '|',
    'insertImage', '|',
    'insertTable', '|',
    'insertHR', '|',
    'clearFormatting', '|',
    // 'spellChecker',
    'html'
  ],
  toolbarButtonsMD: [
    'paste',
    'bold', '|',
    'italic', '|',
    'underline', '|',
    'strikeThrough', '|',
    'fontFamily', '|',
    'fontSize', '|',
    'color', '|',
    'inlineStyle', '|',
    'align', '|',
    'formatOL', '-',
    'formatUL', '|',
    'outdent', '|',
    'indent', '|',
    'insertLink', '|',
    'insertImage', '|',
    'insertTable', '|',
    'insertHR', '|',
    'clearFormatting', '|',
    // 'spellChecker',
    'html'
  ],
  toolbarButtonsSM: [
    'paste',
    'bold', '|',
    'italic', '|',
    'underline', '|',
    'strikeThrough', '|',
    'fontSize', '|',
    'color', '|',
    'align', '|',
    'insertHR'
  ],
  toolbarButtonsXS: [],

  colorsBackground: [
    '#61BD6D', '#000000', '#54ACD2', '#2C82C9', '#9365B8', '#475577',
    '#CCCCCC', '#F7DA64', '#FBA026', '#EB6B56', '#A38F84', '#EFEFEF',
    '#FFFFFF', '#FAC51C', '#F37934', '#D1D5D8', '#ba1a6f', 'REMOVE'
  ],
  colorsStep: 6,
  colorsText: [
    '#61BD6D', '#000000', '#54ACD2', '#2C82C9', '#9365B8', '#475577',
    '#CCCCCC', '#F7DA64', '#FBA026', '#EB6B56', '#A38F84', '#EFEFEF',
    '#FFFFFF', '#FAC51C', '#F37934', '#D1D5D8', '#ba1a6f', 'REMOVE'
  ],

  linkList: [
    {
      text: 'Binotel',
      href: 'http://www.binotel.ua',
      target: '_blank',
      rel: ''
    }, {
      text: 'Binotel | телефонизация офиса',
      href: 'http://www.binotel.ua/callcenter',
      target: '_blank',
      rel: ''
    }, {
      text: 'Binotel | Call Tracking',
      href: 'http://www.binotel.ua/calltracking',
      target: '_blank',
      rel: ''
    }, {
      text: 'Интернет-магазин Binotel',
      href: 'http://www.binotel.ua/store/ip-%D1%82%D0%B5%D0%BB%D0%B5%D1%84%D0%BE%D0%BD%D1%8B-%D0%BE%D1%84%D0%B8%D1%81',
      target: '_blank',
      rel: ''
    }, {
      text: 'example@gmail.com',
      href: 'malto:example@gmail.com',
      target: '_blank',
      rel: ''
    }, {
      text: '+38 098 000 00 00',
      href: 'tel:+380980000000',
      target: '_blank',
      rel: ''
    }
  ],

  inlineStyles: {
    'Style 1': 'font-size: 14px; color: #000000;',
    'Style 2': 'font-size: 14px; color: #ba1a6f;',
  },

  /**
   * Определяет шрифты, которые появляются под кнопкой «Семейство шрифтов» на панели инструментов расширенного текстового редактора.
   */
  fontFamily: {
    'Roboto,sans-serif': 'Roboto',
    'Verdana,sans-serif': 'Verdana',
    'Georgia,sans-serif': 'Georgia',
    'Monospace,sans-serif': 'Monospace',
    'Tahoma,sans-serif': 'Tahoma',
    'Arial,sans-serif': 'Arial'
  },
  fontSizeDefaultSelection: '14',
  fontSizeUnit: 'px',

  /* 2MB - Максимальный размер изображения разрешен при загрузке в байтах */
  imageMaxSize: 1024 * 1024 * 2,
  imageDefaultWidth: 0,
  imagePasteProcess: true,
  imageInsertButtons: ['imageBack', '|', 'imageUpload', 'imageByURL'],
  imageButtons: ['linkImage', 'removeImage'],
  imageDefaultAlign: 'left',
  /**
   * Показывать модальные, чтобы выбрать, сохранять или не стили, вставленные в Word.
   * Если установлено значение false, действие по умолчанию - очищать стили.
   */
  wordPasteModal: true,
  /* Список допустимых атрибутов CSS для тегов при вставке из Word. */
  wordAllowedStyleProps : [
    'font-family',
    'font-size',
    'background',
    'color',
    'width',
    'text-align',
    'vertical-align',
    'background-color',
    'padding',
    'margin' ,
    'height',
    'margin-top',
    'margin-left',
    'margin-right',
    'margin-bottom',
    'text-decoration',
    'font-weight',
    'presentation-letter',
    'font-style'
  ],
  /* Теги, которые удаляются вместе с их содержимым при вставке чего-либо в текстовый редактор из Word. */
  wordDeniedTags : [
    'area', 'audio', 'canvas', 'button', 'caption', 'cite', 'code', 'col', 'colgroup', 'datalist', 'dd', 'del',
    'details', 'dfn', 'dialog', 'embed', 'fieldset', 'figcaption', 'figure', 'form', 'iframe', 'input',
    'map', 'mark', 'menu', 'menuitem', 'meter', 'noscript', 'object', 'optgroup', 'option', 'output',
    'param', 'pre', 'progress', 'queue', 'rp', 'rt', 'ruby', 's', 'samp', 'script', 'select', 'source', 'sub',
    'summary', 'sup', 'textarea', 'time', 'title', 'track', 'u', 'var', 'video', 'wbr'
  ],

  tableCellStyles: {
    '': 'По умолчанию',
    table_td_header: 'Заголовок таблицы',
    table_td_2: 'Ячейка таблицы'
  },
  tableStyles: {
    '': 'По умолчанию',
    table_black: 'Черные рамки',
    table_gray: 'Серые рамки',
    table_white: 'Белые рамки'
  },
  tableDefaultWidth: '100%',
  tableMultipleStyles: false,
  tableResizer: true,
  tableColors : 	[
    '#61BD6D', '#1ABC9C', '#54ACD2', '#2C82C9', '#9365B8', '#475577', '#CCCCCC',
    '#41A85F', '#00A885', '#3D8EB9', '#2969B0', '#553982', '#28324E', '#000000',
    '#F7DA64', '#FBA026', '#EB6B56', '#E25041', '#A38F84', '#EFEFEF', '#FFFFFF',
    '#FAC51C', '#F37934', '#D14841', '#B8312F', '#D1D5D8',
    '#ba1a6f', 'REMOVE'
  ],

  iframe: true,
  iframeStyle: '',
  iframeStyleFiles: [],
  iframeDefaultStyle: '',

  HTML5Map: {
    B: 'STRONG',
    I: 'EM',
    STRIKE: 'STRONG',
    ARTICLE: 'DIV',
    SECTION: 'DIV',
    HEADER: 'DIV',
    FOOTER: 'DIV',
    NAV: 'DIV',
    MAIN: 'DIV'
  },

  /* Оставьте HTML внутри редактора нетронутым без какой-либо специальной обработки, кроме очистки HTML. */
  htmlUntouched : false,
  htmlAllowComments: false,
  /* Список разрешенных тегов. */
  htmlAllowedTags: [
    'a',
    'b',
    'blockquote',
    'br',
    'col',
    'colgroup',
    'datalist',
    'div',
    'dl',
    'dt',
    'em',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'hgroup',
    'hr',
    'img',
    'ins',
    'label',
    'li',
    'link',
    'ol',
    'p',
    'style',
    'small',
    'span',
    'span',
    'strong',
    'table',
    'tbody',
    'td',
    'tfoot',
    'th',
    'thead',
    'tr',
    'ul',
    'center'
  ],
  /* Список тегов, которые удаляются вместе с их контентом. */
  htmlRemoveTags: [
    'script',
    'base'
  ],
  /* Список допустимых атрибутов CSS для тегов. */
  htmlAllowedStyleProps: ['.*'],
  /* Список тегов, которые не удаляются, когда в них нет содержимого. */
  htmlAllowedEmptyTags: [
    'a',
    'div',
    'style',
    'script',
    '.presentation-letter',
    '.fr-toggle-quote',
    // '.presentation-letter-content',
    '.presentation-letter-logo'
  ],
  /* Список тегов, которые не должны быть обернуты внутри тегов блоков. */
  htmlDoNotWrapTags : ['script', 'style', 'img', 'center'],
  /* Список разрешенных атрибутов для тегов. */
  htmlAllowedAttrs: [
    'dir',
    'align',
    'alt',
    'background',
    'bgcolor',
    'border',
    'charset',
    'cellpadding',
    'cellspacing',
    'checked',
    'class',
    'color',
    'cols',
    'colspan',
    'data',
    'disabled',
    'for',
    'height',
    'hidden',
    'high',
    'href',
    'hreflang',
    'http-equiv',
    'icon',
    'id',
    'label',
    'lang',
    'language',
    'list',
    'max',
    'maxlength',
    'min',
    'name',
    'placeholder',
    'readonly',
    'rel',
    'required',
    'rows',
    'rowspan',
    'scope',
    'scoped',
    'scrolling',
    'seamless',
    'selected',
    'shape',
    'size',
    'sizes',
    'span',
    'src',
    'style',
    'tabindex',
    'target',
    'type',
    'translate',
    'valign',
    'width',
    'wrap'
  ],
  /**
   * Удаляет форматирование текста при вставке содержимого в редактор расширенного текста,
   * но сохраняет структуру содержимого.
   */
  pastePlain: true,
  /**
   * Теги, которые удаляются вместе с их содержимым при вставке чего-либо
   * в редактор расширенного текста.
   */
  pasteDeniedTags: [
    'meta'
  ],
  /* Атрибуты, которые удаляются при вставке чего-либо в редактор расширенного текста. */
  pasteDeniedAttrs: [
    'onclick'
  ],
  /* Список допустимых атрибутов CSS для тегов. */
  pasteAllowedStyleProps: [
    'width',
    'max-width',
    'height',
    'max-height',
    'display',
    'font',
    'font-family',
    'font-size',
    'font-weight',
    'text-align',
    'text-decoration',
    'line-height',
    'white-space',
    'vertical-align',
    'color',
    'vertical-align',
    'background',
    'background-color',
    'background-*',
    'font-*',
    'text-*',

    'border',
    'border-*',
    'border-top',
    'border-right',
    'border-bottom',
    'border-left',
    'border-style',
    'border-width',
    'border-color',

    'padding',
    'padding-top',
    'padding-right',
    'padding-bottom',
    'padding-left',

    'margin',
    'margin-top',
    'margin-right',
    'margin-bottom',
    'margin-left'
  ],
  pluginsEnabled: [
    'presentationLetterTemplate',
    'toggleQuote',
    'extendedTable',
    // 'spellChecker',
    'lineHeight',
    'fullscreen',
    /**
     * Добавляет параметр выравнивания.
     */
    'align',
    // 'charCounter',
    /**
     * 	Улучшает код внутри режима просмотра кода.
     */
    'codeBeautifier',
    /**
     * 	Включает просмотр кода для содержимого редактора.
     */
    'codeView',
    /**
     * Добавляет возможность изменения цвета фона и текста.
     */
    'colors',
    /**
     * Добавляет, чтобы перетаскивать содержимое. Например: изображения, видео.
     */
    'draggable',
    /**
     * Встраивает любой контент из Интернета в редактор
     */
    'embedly',
    /**
     * Преобразует символы в специальные объекты HTML.
     */
    'entities',
    /**
     * Позволяет пользователям выбирать из разных типов шрифтов.
     */
    'fontFamily',
    /**
     * Позволяет пользователям изменять размер шрифта с точностью до пикселя.
     */
    'fontSize',
    /**
     * Добавляет полноэкранный режим.
     */
    // 'fullscreen',
    /**
     * Включает расширенное редактирование изображений.
     */
    'image',
    /**
     * Просмотрите и удалите изображения на своем сервере.
     */
    'imageManager',
    /**
     * Определите пользовательские стили для выбранного текста.
     */
    'inlineStyle',
    /**
     * Помощник для добавления новых строк между элементами, такими как таблицы.
     */
    'lineBreaker',
    /**
     * 	Включает расширенное редактирование ссылок.
     */
    'link',
    /**
     * 	Позволяет пользователям вставлять списки в редактор.
     */
    'lists',
    /**
     * Позволяет пользователям изменять тип абзаца.
     */
    'paragraphFormat',
    /**
     * Позволяет пользователю выбирать стиль для абзаца.
     */
    'paragraphStyle',
    /**
     * Помощник для добавления изображений, таблиц и прочего.
     */
    'quickInsert',
    /**
     * Добавляет опцию котировки.
     */
    // 'quote',
    /**
     * Включает AJAX сохранение содержимого внутри редактора.
     */
    // 'save',
    /**
     * Основные и расширенные операции с ячейками, строками и столбцами.
     */
    'table',
    /**
     * 	Преобразуйте текст в URL по мере ввода.
     */
    'url',
    /**
     * Очищает HTML, вставленный из Word.
     */
    'wordPaste'
  ]
});
// https://www.froala.com/wysiwyg-editor/docs/concepts/custom/icon
// (<any>$).FroalaEditor.ICON_DEFAULT_TEMPLATE = 'material_design';
// (<any>$).FroalaEditor.DefineIconTemplate('material_design', '<i class="material-icons">[NAME]</i>');
// (<any>$).FroalaEditor.DefineIcon('bold', {NAME: 'format_bold'});
// (<any>$).FroalaEditor.DefineIcon('italic', {NAME: 'format_italic'});
// (<any>$).FroalaEditor.DefineIcon('underline', {NAME: 'format_underlined'});
// (<any>$).FroalaEditor.DefineIcon('strikeThrough', {NAME: 'strikethrough_s'});
// (<any>$).FroalaEditor.DefineIcon('fontFamily', {NAME: 'font_download'});
// (<any>$).FroalaEditor.DefineIcon('fontSize', {NAME: 'format_size'});
// (<any>$).FroalaEditor.DefineIcon('color', {NAME: 'format_color_reset'});
// (<any>$).FroalaEditor.DefineIcon('inlineStyle', {NAME: 'line_style'});
// (<any>$).FroalaEditor.DefineIcon('insertImage', {NAME: 'photo'});
// (<any>$).FroalaEditor.DefineIcon('insertTable', {NAME: 'grid_on'});
//
// (<any>$).FroalaEditor.DefineIcon('align', {NAME: 'format_align_left'});
// (<any>$).FroalaEditor.DefineIcon('align-left', {NAME: 'format_align_left'});
// (<any>$).FroalaEditor.DefineIcon('align-right', {NAME: 'format_align_right'});
// (<any>$).FroalaEditor.DefineIcon('align-center', {NAME: 'format_align_center'});
// (<any>$).FroalaEditor.DefineIcon('align-justify', {NAME: 'format_align_justify'});
//
// (<any>$).FroalaEditor.DefineIcon('outdent', {NAME: 'format_indent_decrease'});
// (<any>$).FroalaEditor.DefineIcon('indent', {NAME: 'format_indent_increase'});
// (<any>$).FroalaEditor.DefineIcon('insertHR', {NAME: 'linear_scale'});
// (<any>$).FroalaEditor.DefineIcon('clearFormatting', {NAME: 'layers_clear'});

