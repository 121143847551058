import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Routes } from '@angular/router';
import { LoginComponent } from './page/login/login.component';
import { NotFoundComponent } from './page/not-found/not-found.component';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { GapiCanGuard } from './core/guard/gapi-can.guard';
import { PermissionsComponent } from './page/permissions/permissions.component';
import { AppConfig } from './app.config';
export function createTranslateLoader(http) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
const DEFAULT_PERFECT_SCROLLBAR_CONFIG = {
    suppressScrollX: true,
    wheelSpeed: 1,
    minScrollbarLength: 30,
    maxScrollbarLength: 50 // Максимальный размер (px) для полосы прокрутки
};
const appRoutes = [
    {
        path: 'mail',
        loadChildren: './mail/mail.module#MailModule',
        canLoad: [GapiCanGuard],
        runGuardsAndResolvers: 'paramsOrQueryParamsChange'
    }, {
        path: 'setting',
        loadChildren: './setting/setting.module#SettingModule',
        canLoad: [GapiCanGuard],
    }, {
        path: 'statistic',
        loadChildren: './statistic/statistic.module#StatisticModule',
        canLoad: [GapiCanGuard]
    }, {
        path: 'profile',
        loadChildren: './profile/profile.module#ProfileModule',
        canLoad: [GapiCanGuard]
    }, {
        path: 'login',
        component: LoginComponent,
        canActivate: [GapiCanGuard],
        runGuardsAndResolvers: 'paramsOrQueryParamsChange'
    }, {
        path: 'permissions',
        component: PermissionsComponent
    }, {
        path: '',
        redirectTo: 'mail',
        pathMatch: 'full'
    }, {
        path: '**',
        component: NotFoundComponent
    }
];
/**
 * https://hackernoon.com/hook-into-angular-initialization-process-add41a6b7e
 *   <script type="text/javascript" async defer
 *   src="https://apis.google.com/js/api.js" onload='this.onload=function(){};handleClientLoad()'
 *   onreadystatechange='if(this.readyState==="complete") this.onload()'></script>
 */
export function onAppInit1() {
    return () => {
        return new Promise((resolve) => {
            window.onload = () => {
                window.gapi.load('client:auth2', function () {
                    /**
                     * Сначала подгружаем API google а потом приложения
                     */
                    window.gapi.client.load('gmail', 'v1', () => {
                        /**
                         * Аутентификация с использованием клиентской библиотеки API Google
                         * https://developers.google.com/api-client-library/javascript/features/authentication
                         */
                        window.gapi.auth2.init({
                            client_id: AppConfig.clientId,
                            scope: AppConfig.scope
                        }).then((googleAuth) => {
                            // console.log('[main] googleAuth: ', googleAuth);
                            if (googleAuth && googleAuth.isSignedIn.get()) {
                                // console.log('isSignedIn');
                                console.log('[main] isSignedIn', googleAuth.isSignedIn.get());
                            }
                            else {
                                window.location.assign('#/login');
                            }
                            resolve();
                        }, (reason) => {
                            if (reason.result && reason.result.error) {
                                if (reason.result.error.message) {
                                    alert('Error: ' + reason.result.error.message);
                                }
                                else {
                                    alert('Error: Google init Auth');
                                }
                            }
                            else if (reason.error === 'idpiframe_initialization_failed') {
                                /**
                                 * В настройках браузера заблонированы куки
                                 * chrome://settings/content/cookies
                                 * about:preferences
                                 */
                                if (!('idpiframeInitializationFailed' in window)) {
                                    window.idpiframeInitializationFailed = true;
                                }
                            }
                            else {
                                alert('Error: Google init Auth');
                            }
                            resolve();
                        });
                    });
                });
            };
        });
    };
}
const ɵ0 = (createTranslateLoader), ɵ1 = DEFAULT_PERFECT_SCROLLBAR_CONFIG;
export class AppModule {
}
export { ɵ0, ɵ1 };
