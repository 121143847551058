/* tslint:disable:only-arrow-functions */
import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientJsonpModule, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';

import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {FormsModule} from '@angular/forms';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

import {SimpleNotificationModule} from 'projects/simple-notification';
import {ProgressBarModule} from 'projects/progress-bar';
import {FrEditorModule} from 'projects/fr-editor';

import {AppComponent} from './app.component';
import {LoginComponent} from './page/login/login.component';
import {NotFoundComponent} from './page/not-found/not-found.component';
import {BsDropdownModule, CollapseModule} from 'ngx-bootstrap';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';

import {GapiCanGuard} from './core/guard/gapi-can.guard';
import {PermissionsComponent} from './page/permissions/permissions.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {HttpErrorInterceptorService, HttpJwtInterceptorService} from './core/service';
import {AppConfig} from './app.config';


export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true, // Отключить ось X во всех ситуациях
  wheelSpeed: 1, // Скорость прокрутки для события mousewheel
  minScrollbarLength: 30, // Минимальный размер (px) для полосы прокрутки
  maxScrollbarLength: 50 // Максимальный размер (px) для полосы прокрутки
};

const appRoutes: Routes = [
  {
    path: 'mail',
    loadChildren: './mail/mail.module#MailModule',
    canLoad: [GapiCanGuard],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  }, {
    path: 'setting',
    loadChildren: './setting/setting.module#SettingModule',
    canLoad: [GapiCanGuard],
  }, {
    path: 'statistic',
    loadChildren: './statistic/statistic.module#StatisticModule',
    canLoad: [GapiCanGuard]
  }, {
    path: 'profile',
    loadChildren: './profile/profile.module#ProfileModule',
    canLoad: [GapiCanGuard]
  }, {
    path: 'login',
    component: LoginComponent,
    canActivate: [GapiCanGuard],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  }, {
    path: 'permissions',
    component: PermissionsComponent
  }, {
    path: '',
    redirectTo: 'mail',
    pathMatch: 'full'
  }, {
    path: '**',
    component: NotFoundComponent
  }
];

/**
 * https://hackernoon.com/hook-into-angular-initialization-process-add41a6b7e
 *   <script type="text/javascript" async defer
 *   src="https://apis.google.com/js/api.js" onload='this.onload=function(){};handleClientLoad()'
 *   onreadystatechange='if(this.readyState==="complete") this.onload()'></script>
 */
export function onAppInit1(): () => Promise<any> {
  return (): Promise<any> => {
    return new Promise((resolve) => {

      window.onload = () => {

        (window as any).gapi.load('client:auth2', function() {
          /**
           * Сначала подгружаем API google а потом приложения
           */
          (window as any).gapi.client.load('gmail', 'v1', () => {
            /**
             * Аутентификация с использованием клиентской библиотеки API Google
             * https://developers.google.com/api-client-library/javascript/features/authentication
             */
            (window as any).gapi.auth2.init({
              client_id: AppConfig.clientId,
              scope: AppConfig.scope
            }).then((googleAuth) => {

              // console.log('[main] googleAuth: ', googleAuth);
              if (googleAuth && googleAuth.isSignedIn.get()) {
                // console.log('isSignedIn');
                console.log('[main] isSignedIn', googleAuth.isSignedIn.get());
              } else {
                window.location.assign('#/login');
              }

              resolve();
            }, (reason: any) => {
              if (reason.result && reason.result.error) {
                if (reason.result.error.message) {
                  alert('Error: ' + reason.result.error.message);
                } else {
                  alert('Error: Google init Auth');
                }
              } else if (reason.error === 'idpiframe_initialization_failed') {
                /**
                 * В настройках браузера заблонированы куки
                 * chrome://settings/content/cookies
                 * about:preferences
                 */
                if (!('idpiframeInitializationFailed' in window)) {
                  (window as any).idpiframeInitializationFailed = true;
                }
              } else {
                alert('Error: Google init Auth');
              }

              resolve();
            });

          });
        });
      };
    });
  };
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NotFoundComponent,
    PermissionsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production
    }),
    RouterModule.forRoot(appRoutes, {
      enableTracing: false,
      useHash: true,
      preloadingStrategy: PreloadAllModules,
      // onSameUrlNavigation: 'reload'
    }),
    HttpClientModule,
    HttpClientJsonpModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    SimpleNotificationModule.withConfig({
      position: ['bottom', 'right'],
      timeOut: 3000,
      lastOnBottom: true,
      showProgressBar: true,
      pauseOnHover: true,
      clickToClose: true
    }),
    ProgressBarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    FrEditorModule,
    NgbModule,
    CollapseModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production})
  ],
  providers: [
    GapiCanGuard,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {provide: HTTP_INTERCEPTORS, useClass: HttpJwtInterceptorService, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptorService, multi: true},
    {
      provide: APP_INITIALIZER,
      useFactory: onAppInit1,
      multi: true,
      deps: [/* your dependencies */]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

