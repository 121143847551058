import { NgZone } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/service-worker";
/**
 * Обновления кеша WPA
 */
export class CheckForUpdateService {
    constructor(swUpdate, zone) {
        this.swUpdate = swUpdate;
        this.zone = zone;
        if (environment.production && environment.local && 'serviceWorker' in navigator) {
            navigator.serviceWorker.getRegistration()
                .then(active => {
                if (!active) {
                    return navigator.serviceWorker.register('ngsw-worker.js');
                }
            }).catch(console.log);
        }
        if (this.swUpdate.isEnabled) {
            const updateInterval = 1000 * 60 * 5;
            interval(updateInterval)
                .subscribe(() => {
                return this.zone.run(() => {
                    return this.swUpdate.checkForUpdate();
                });
            });
            this.swUpdate.available.subscribe(() => {
                this.deleteCaches();
                if (confirm('New version available. Load New Version?')) {
                    this.swUpdate.activateUpdate().then(() => document.location.reload());
                    // window.location.reload();
                }
            });
        }
    }
    /**
     * удаления кеша WPA
     * https://developers.google.com/web/ilt/pwa/caching-files-with-service-worker
     */
    deleteCaches() {
        if ('caches' in window) {
            window.caches.keys().then((data) => {
                if (data && Array.isArray(data)) {
                    data.forEach((keyCache) => {
                        return window.caches.delete(keyCache);
                    });
                }
            });
        }
    }
}
CheckForUpdateService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CheckForUpdateService_Factory() { return new CheckForUpdateService(i0.ɵɵinject(i1.SwUpdate, 8), i0.ɵɵinject(i0.NgZone)); }, token: CheckForUpdateService, providedIn: "root" });
