import {Subject} from 'rxjs';
import {MessageCodingModel} from './message-coding.model';
import {crc32} from '../fn';
import {DomStrModel} from './dom-str.model';

declare const Buffer;

/**
 * Обработка вложений письма
 */
export class AttachmentModel {

  static readonly searchBase64ImageReGex = /src="(data:image\/([a-zA-Z]+);base64,([a-zA-Z0-9+\/=]+))"/g;

  base64: string;
  filename: string;
  contentType: string;
  sliceSize: number;

  /**
   * Типы вложений
   * https://cloud.google.com/appengine/docs/standard/python/mail/mail-with-headers-attachments
   */
  types = {
    docx: 'application/msword',
    doc: 'application/msword',
    pdf: 'application/pdf',
    rss: 'application/rss+xml',
    kml: 'application/vnd.google-earth.kml+xml',
    kmz: 'application/vnd.google-earth.kmz\tkmz',
    xlsx: 'application/vnd.ms-excel',
    xls: 'application/vnd.ms-excel',
    pptx: 'application/vnd.ms-powerpoint',
    ppt: 'application/vnd.ms-powerpoint',
    pps: 'application/vnd.ms-powerpoint',
    odp: 'application/vnd.oasis.opendocument.presentation',
    ods: 'application/vnd.oasis.opendocument.spreadsheet',
    odt: 'application/vnd.oasis.opendocument.text',
    sxc: 'application/vnd.sun.xml.calc',
    sxw: 'application/vnd.sun.xml.writer',
    gzip: 'application/x-gzip',
    zip: 'application/zip',
    snd: 'audio/basic',
    au: 'audio/basic',
    flac: 'audio/flac',
    rmi: 'audio/mid',
    mid: 'audio/mid',
    m4a: 'audio/mp4',
    mp3: 'audio/mpeg',
    ogg: 'audio/ogg',
    oga: 'audio/ogg',
    aiff: 'audio/x-aiff',
    aifc: 'audio/x-aiff',
    aif: 'audio/x-aiff',
    wav: 'audio/x-wav',
    gif: 'image/gif',
    jpe: 'image/jpeg',
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    png: 'image/png',
    tif: 'image/tiff',
    tiff: 'image/tiff',
    wbmp: 'image/vnd.wap.wbmp',
    bmp: 'image/x-ms-bmp',
    ics: 'text/calendar',
    csv: 'text/comma-separated-values',
    css: 'text/css',
    html: 'text/html',
    htm: 'text/html',
    txt: 'text/plain',
    text: 'text/plain',
    pot: 'text/plain',
    patch: 'text/plain',
    log: 'text/plain',
    diff: 'text/plain',
    c: 'text/plain',
    asc: 'text/plain',
    vcf: 'text/x-vcard',
    mp4: 'video/mp4',
    mpe: 'video/mpeg',
    mpg: 'video/mpeg',
    mpeg: 'video/mpeg',
    ogv: 'video/ogg',
    mov: 'video/quicktime',
    qt: 'video/quicktime',
    avi: 'video/x-msvideo'
  };
  /**
   * Ищем img(base64) и заменяем их на img(cid:filename)
   * саму картинку прикрепляем в attach
   */
  static async cidAdaptationDOM(DOMEmail: DomStrModel): Promise<any> {
    const attachments: any = {};
    let key;
    try {
      for (key in DOMEmail.dom.images) {

        if (DOMEmail.dom.images[key].src && DOMEmail.dom.images[key].src.indexOf('data:image/') === 0) {

          const attach = await new AttachmentModel(DOMEmail.dom.images[key].src);

          const res: any = attach.toAttachment(false, +key);
          // const filename = `${key}_${res.name}`;
          // res.name = filename;
          DOMEmail.dom.images[key].src = `cid:${res.name}`;

          attachments[res.name] = res;
        }
      }

      return attachments;
    } catch (e) {
      return e;
    }
  }
  /**
   * Ищем img(base64) и заменяем их на img(cid:filename)
   * саму картинку прикрепляем в attach
   */
  static async cidAdaptation(data: string): Promise<{
    textHtml: string,
    attachments: IAttachmentSend
  }> {


    const regex = AttachmentModel.searchBase64ImageReGex;
    let match;
    const attachments: any = {};
    let result = data;

    while ((match = regex.exec(data)) !== null) {

      if (match.index === regex.lastIndex) {
        regex.lastIndex++;
      }

      const attach = await new AttachmentModel(match[1]);

      const res: any = attach.toAttachment();
      // attachments[attach.filename] = await attach.toAttachment();
      attachments[res.name] = res;
      result = await attach.replaceAttachmentToCid(result, match[1], attach.filename);
    }

    return {
      textHtml: result,
      attachments: attachments
    };
  }

  constructor(base64url: string, filename?: string, contentType?: string, sliceSize?: number) {

    this.contentType = contentType || '';
    this.filename = filename;
    this.sliceSize = sliceSize || 512;
    this.normalizeBase64(base64url);
  }

  normalizeBase64(base64: string): AttachmentModel {

    this.base64 = base64.replace(/-/g, '+').replace(/_/g, '/');

    return this;
  }

  /**
   * Скачать вложения
   */
  download(): void {

    if (this.filename) {
      const elem = window.document.createElement('a');

      elem.href = window.URL.createObjectURL(this.base64toBlob());

      elem.download = this.filename;

      document.body.appendChild(elem);
      elem.click();
      document.body.removeChild(elem);
    }
  }

  /**
   * data:{mime/type string};base64,{base64 string}
   */
  getDataImageBase64(): string {

    const mimeType = this.getMimeTypes();

    return mimeType ? 'data:' + mimeType + ';base64,' + this.base64 : '';
  }

  /**
   * https://developer.mozilla.org/ru/docs/Web/API/URL/createObjectURL
   */
  getBlobURL(): string {
    return window.URL.createObjectURL(this.base64toBlob());
  }

  /**
   * https://developer.mozilla.org/ru/docs/Web/API/Blob/Blob
   */
  getBlob(): Blob {
    return this.base64toBlob();
  }

  getMimeTypes(): string | null {

    const extension = this.getExtension();

    return this.types[extension] ? this.types[extension] : null;
  }

  /**
   * Получить расширение
   */
  getExtension(): string {

    const extension = this.filename.replace(/^.*?\.([a-zA-Z0-9]+)$/, '$1');

    return extension ? extension.toLowerCase().trim() : '';
  }

  /**
   * Преобразуем image base64 в attachment для отправки
   * @param {boolean} cid
   * @param {number} num
   * @returns {{
   *      mime: string,
    *     name: string;
    *     size: number;
    *     type: string;
    *     cid: boolean
    * }}
   */
  toAttachment(cid: boolean = false, num: number = 0): IAttachmentSend {

    const regex = /data:image\/(.+);base64,([a-zA-Z0-9+\/=]+)/m;
    let m;

    if ((m = regex.exec(this.base64)) !== null) {

      this.base64 =  MessageCodingModel.enReplace(m[2]);
      this.base64 =  m[2];

      if (!this.filename) {
        this.filename = num + crc32(m[2]) + '.' + m[1];
      } else {
        this.filename = num + this.filename;
      }

      this.contentType = 'image/' + m[1];
    }

    return {
      mime: this.getMimeMessage(),
      name: this.filename,
      size: Buffer.byteLength(this.base64),
      type: this.contentType,
      cid: cid
    };
  }

  /**
   * Меняем src=base64 на src=cid:filename
   */
  replaceAttachmentToCid(htmlMsg: string, match: string, filename: string): string {

    let startStr, endStr, searchValue;

    if (htmlMsg.length > 100) {

      startStr = match.substr(0, 50)
        .replace(/\+/g, '\\+')
        .replace(/\//g, '\\/');
      endStr = match.substr(-50)
        .replace(/\+/g, '\\+')
        .replace(/\//g, '\\/');

      return htmlMsg.replace(new RegExp(startStr + '.+' + endStr, 'gi'), 'cid:' + filename);
    } else {

      searchValue = match.replace(/\+/g, '\\+').replace(/\//g, '\\/');

      return htmlMsg.replace(new RegExp(searchValue, 'gi'), 'cid:' + filename);
    }
  }

  /**
   * Формируем для отправки для отправки вложения
   */
  private getMimeMessage(): string {

    const nl = '\n';

    if (!this.contentType || !this.filename || !this.base64) {
      return null;
    }

    return 'Content-Type: "' + this.contentType + '"; name="' + this.filename + '"' + nl +
      'Content-Disposition: attachment; filename="' + this.filename + '"' + nl +
      'Content-Transfer-Encoding: base64' + nl +
      'Content-ID: <' + this.filename + '>' + nl +
      '' + nl + nl + this.base64;
  }

  private base64toBlob(): Blob {

    const byteCharacters = window.atob(this.base64);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += this.sliceSize) {
      const slice = byteCharacters.slice(offset, offset + this.sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, {
      type: this.contentType
    });
  }
}

/**
 * Загружаем файл с ПК
 */
export class LoadingAttachment {

  public result: Subject<IAttachmentSend> = new Subject();

  name:  string;
  type:  string;
  size:  number;

  progress:  number;
  /**
   * @tutorial https://developer.mozilla.org/ru/docs/Web/API/FileReader
   */
  reader:  FileReader;

  constructor(file: File) {

    this.name = file.name;
    this.size = file.size;
    this.type = file.type;

    this.reader = new FileReader();

    this.reader.onload = (e: any) => {
      this.onLoad(e.target);
    };

    this.reader.readAsDataURL(file);
  }

  /**
   * Файл загружен успешно
   */
  private onLoad(target: FileReader) {

    this.type = (this.type && this.type.length) ? this.type : 'application/octet-stream';

    this.result.next({
      type: this.type,
      name: this.name,
      size: this.size,
      mime: this.getMime(this.getBase64(<string>target.result), this.type)
    });
  }

  /**
   * Достаем содержымое файла в Base64
   */
  private getBase64(dataBase64: string): string {

    const regex = /^data:(.*);base64,(.+)/;
    let m;

    if ((m = regex.exec(dataBase64)) !== null) {
      return m[2];
    } else {
      return '';
    }
  }

  private getMime(base64: any, type: string = ''): string {

    const nl = '\n';

    return 'Content-Type: "' + type + '"; name="' + this.name + '"' + nl +
      'Content-Disposition: attachment; filename="' + this.name + '"' + nl +
      'Content-Transfer-Encoding: base64' + nl +
      '' + nl + nl + base64;
  }
}

/**
 * Клас для загрузки файла с сервера
 * и формирование атача для отправки в письме
 */
export class UrlToBase64Attachment {

  private contentType: string;
  private size: number;

  static getAttachment(url: string, filename?: string) {
    return new UrlToBase64Attachment(url, filename).attachment();
  }

  constructor(
    private url: string,
    private filename?: string
  ) {}

  attachment(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.download()
        .then((base64: string) => {
          resolve(this.getResult(base64));
        })
        .catch((err: any) => reject(err));
    });
  }

  /**
   * Качаем файл с сервера
   */
  private download(): Promise<any> {
    return new Promise((resolve, reject) => {

      const request = new XMLHttpRequest();
      request.open('GET', this.url, true);
      request.withCredentials = true;
      request.responseType = 'blob';
      request.onload = () => {
        const reader = new FileReader();
        reader.readAsDataURL(request.response);
        reader.onload =  (e: any) => {
          this.contentType = request.response.type;
          this.size = request.response.size;
          const list = e.target.result.split(';base64,');
          resolve(list[1]);
        };
        reader.onerror =  () => {
          reject(new Error('Error load file.'));
        };
      };
      request.send();
    });
  }

  /**
   * Файл загружен успешно
   */
  private getResult(base64: string) {
    return {
      type: this.contentType,
      name: this.filename,
      size: this.size,
      mime: this.getMime(base64)
    };
  }

  private getMime(base64: string): string {
    const nl = '\n';
    return 'Content-Type: "' + this.contentType + '"; name="' + this.filename + '"' + nl +
      'Content-Disposition: attachment; filename="' + this.filename + '"' + nl +
      'Content-Transfer-Encoding: base64' + nl +
      '' + nl + nl + base64;
  }
}

export interface IAttachmentSend {
  type: string;
  name: string;
  size: number;
  mime: string;
  cid?: boolean;
}
