import {timer} from 'rxjs';

/**
 * Таймер
 */
export class TimerModel {

  private time = 0;
  private timer: any;
  private subscription: any;

  static new() {
    return new TimerModel;
  }

  constructor() {
    this.init();
  }

  private init() {
    this.timer = timer(0, 1000);
  }

  start() {

    this.init();

    this.stop();

    this.subscription = this.timer.subscribe((time: number) => {
      if (this.time < 3600) {
        this.time++;
      }
    });
  }

  stop() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    return this.time;
  }

  restart() {
    this.time = 0;

    this.init();
    this.start();
  }
}
