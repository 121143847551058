import * as tslib_1 from "tslib";
import { NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, timer } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { AppConfig } from '../../app.config';
import { Auth } from '../model';
import { SettingService } from './setting.service';
import { WsService } from './ws.service';
import { ContactsService } from './contacts.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./setting.service";
import * as i3 from "./contacts.service";
import * as i4 from "./ws.service";
export class GApiService {
    constructor(router, setting, contacts, ws, ngZone) {
        this.router = router;
        this.setting = setting;
        this.contacts = contacts;
        this.ws = ws;
        this.ngZone = ngZone;
        this.isSignedIn = new BehaviorSubject(null);
        this.auth = new BehaviorSubject(null);
        this.authResponse = new BehaviorSubject(null);
        /**
         * обновить токен доступа
         */
        this.isRefreshToken = new BehaviorSubject(false);
        this.isInit = false;
        /**
         * Интервал времени по которому проверяется жизнь сессии
         */
        this.trackerSession = timer(new Date(), 60000);
        /**
         * После авторизации на сервере поверяем присутствие (sendAs) у пользователя.
         * Если ево нет то загружаем с google и сохраняем в DB
         */
        this.ws.auth.pipe(distinctUntilChanged())
            .subscribe((auth) => {
            // @TODO старый параметр [_id]
            // console.log(auth)
            if (auth && auth._id && auth.id) {
                if (auth.sendAs && auth.sendAs.length > 0) {
                    this.setting.paramGMail.next(auth.sendAs);
                }
                else {
                    this.getListSettingSendAs(auth.id).then((sendAs) => {
                        this.setting.paramGMail.next(sendAs);
                        if (sendAs && sendAs.length) {
                            this.ws.toSend({
                                cf: 'user.update',
                                data: {
                                    id: auth._id,
                                    data: {
                                        sendAs: sendAs || []
                                    }
                                }
                            }, true)
                                .then((_user) => { })
                                .catch((err) => {
                                console.log(err);
                            });
                        }
                    });
                }
            }
        });
        this.auth.subscribe((auth) => {
            if (auth) {
                const authResponse = gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse();
                this.authResponse.next(authResponse);
                if (authResponse) {
                    this.sessionMonitoring();
                    if (authResponse && authResponse.access_token) {
                        this.contacts.init(authResponse.access_token, auth.email);
                    }
                }
            }
        });
        this.isRefreshToken.pipe(distinctUntilChanged()).subscribe((isRefresh) => {
            if (isRefresh) {
                this.refreshToken();
            }
        });
    }
    /**
     * Продливаем token доступа к google
     */
    refreshToken() {
        console.log('refreshToken');
        gapi.auth2.getAuthInstance().currentUser.get().reloadAuthResponse()
            .then((AuthResponse) => {
            console.log(AuthResponse);
            this.isSignedIn.next(true);
            this.authResponse.next(AuthResponse);
            this.isRefreshToken.next(false);
        })
            .catch((e) => {
            console.log(e);
            this.isSignedIn.next(false);
            this.authResponse.next(null);
            this.isRefreshToken.next(false);
            return this.signOut();
        });
    }
    /**
     * Монитор сессию
     * если до окончания сесии остается меньше 10% то продливаем ее
     */
    sessionMonitoring() {
        this.trackerSession.subscribe((step) => {
            const authResponse = this.authResponse.getValue();
            if (authResponse) {
                if (authResponse.expires_at) {
                    const theEnd = Math.ceil((authResponse.expires_at - Date.now()) / 1000);
                    const unitEnd = Math.ceil(authResponse.expires_in * 0.10);
                    // console.log(`${theEnd} < ${unitEnd}`);
                    if (theEnd < unitEnd) {
                        this.isRefreshToken.next(true);
                    }
                }
            }
        });
    }
    init() {
        return new Promise((resolve) => {
            if (this.isInit) {
                resolve(true);
            }
            else {
                const googleAuth = gapi.auth2.getAuthInstance();
                this.isSignedIn.next(googleAuth.isSignedIn.get());
                const currentUser = googleAuth.currentUser.get();
                if (currentUser) {
                    this.isScopes(currentUser.getAuthResponse());
                }
                this.initCurrentUser(currentUser.getBasicProfile());
                /**
                 * Мониторим авторизацию пользователя в google
                 */
                googleAuth.isSignedIn.listen((isSignedIn) => {
                    if (isSignedIn) {
                        this.initCurrentUser(googleAuth.currentUser.get().getBasicProfile());
                        this.isScopes(googleAuth.currentUser.get().getAuthResponse());
                    }
                    else {
                        this.initCurrentUser(null);
                        /**
                         * Если пользователь вышел из google
                         * то обновляем token
                         */
                        this.isRefreshToken.next(true);
                    }
                });
                this.isInit = true;
                resolve(true);
            }
        });
    }
    /**
     * Проверяем есть ли разрешения для scope
     * @param authResponse
     */
    isScopes(authResponse) {
        if (authResponse.scope && authResponse.scope.indexOf('https://www.googleapis.com/auth/contacts') === -1) {
            const googleAuth = gapi.auth2.getAuthInstance();
            googleAuth.disconnect();
            googleAuth.signOut();
            return window.location.reload();
        }
    }
    /**
     * Войти в систему
     * https://developers.google.com/api-client-library/javascript/reference/referencedocs#gapiauth2clientconfig
     */
    signIn() {
        return new Promise((resolve) => {
            gapi.auth2.getAuthInstance().signIn({
                fetch_basic_profile: true,
                prompt: 'select_account',
                scope: AppConfig.scope,
            }).then((auth) => {
                this.isSignedIn.next(true);
                this.initCurrentUser(auth.getBasicProfile());
                this.isScopes(auth.getAuthResponse());
                resolve(true);
                return this.ngZone.run(() => {
                    return this.router.navigateByUrl('/');
                });
            }, (err) => {
                console.log(err);
                resolve(false);
            });
        });
    }
    /**
     * Выход из системы
     */
    signOut() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.isSignedIn.next(false);
            yield this.auth.next(null);
            yield gapi.auth2.getAuthInstance().signOut();
            yield this.ws.toSend({
                cf: 'user.signOut',
                data: {}
            }, true).then((status) => {
                this.ws.auth.next(null);
            }).catch((err) => {
                console.log(err);
            });
            return this.ngZone.run(() => {
                window.location = '#/login';
                return this.router.navigateByUrl('/login');
            });
        });
    }
    initCurrentUser(profile) {
        if (profile) {
            const auth = new Auth(profile.getId(), profile.getEmail(), profile.getFamilyName(), profile.getGivenName(), profile.getName(), profile.getImageUrl());
            if (auth.id && auth.id.length) {
                this.auth.next(auth);
                this.ws.init(auth);
                this.getListSettingSendAs(auth.id).then((response) => {
                    this.setting.paramGMail.next(response);
                });
            }
        }
        else {
            this.auth.next(null);
            this.setting.paramGMail.next([]);
        }
    }
    /**
     * Перечисляет псевдонимы sendAs для указанной учетной записи.
     * Результат включает основной адрес отправки, связанный с учетной записью,
     * а также любые пользовательские псевдонимы "from".
     *
     * @link https://developers.google.com/gmail/api/v1/reference/users/settings/sendAs/list
     */
    getListSettingSendAs(userId) {
        return new Promise((resolve) => {
            gapi.client.gmail.users.settings.sendAs.list({
                userId: userId
            }).then((response) => {
                resolve(response.result.sendAs || []);
            }, (err) => {
                console.log(err);
                resolve([]);
            });
        });
    }
}
GApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GApiService_Factory() { return new GApiService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.SettingService), i0.ɵɵinject(i3.ContactsService), i0.ɵɵinject(i4.WsService), i0.ɵɵinject(i0.NgZone)); }, token: GApiService, providedIn: "root" });
