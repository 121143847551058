import {Base64} from 'js-base64';

/**
 * Обработка сообщения
 */
export class MessageCodingModel {

  static styleBlockquote = 'margin:0 0 0 8px;border-left:1px solid #ccc;padding-left:5px';

  static decode(str: string): string {
    return Base64.decode(MessageCodingModel.deReplace(str));
  }

  static encode(str: string): string {
    return MessageCodingModel.enReplace(Base64.encode(str));
  }

  static deReplace(str: string): string {

    return str.replace(/-/g, '+').replace(/_/g, '/');
  }

  static plainToHtml(str: string): string {
    return str.replace(/\r\n?|\n/g, '<br>');
  }

  static enReplace(str: string): string {
    return str.replace(/\+/g, '-').replace(/\//g, '_');
  }

  static removeQuotes(str: string): string {
    return str.replace(/\x5C/g, '');
  }

  /**
   * Определить является ли строка HTML
   * @param {string} str
   * @returns {boolean}
   */
  static isHtml(str: string): boolean {

    const htmlRegex = new RegExp('<(?=.*? .*?\\/ ?>|br|hr|blockquote|div|font|!--|wbr)[a-z]+.*?>|<([a-z]+).*?<\\/\\1>');

    return htmlRegex.test(str);
  }

  /**
   * Формируем сообщения для ответа
   * @param {string} html текст сообщения
   * @param {string} date
   * @param {string} email
   * @param {string} name
   * @returns {Promise<string>}
   */
  static getReply(html: string, date: string, email: string, name?: string): Promise<string> {

    if (name) {
      email = `"${name}" &#60;${email}&#62;`;
    } else {
      email = `&#60;${email}&#62;`;
    }

    return new Promise((resolve) => {

      MessageCodingModel.quoteMessage(html).then((body: string) => {
        resolve(`<br><br>
                 <div class="gmail_quote">
                    <div dir="ltr">
                        ${date}: <span dir="ltr">${email}</span><br>
                    </div>
                   ${body}
                 </div>`);
      });
    });
  }

  /**
   * Формируем сообщения для пересылки
   * @param {string} html
   * @param {string} from
   * @param {string} date
   * @param {string} subject
   * @param {string} to
   * @returns {Promise<string>}
   */
  static getForward(html: string, date: string, subject: string, from?: any, to?: any): Promise<string> {

    let result =  '---------- Forwarded message,,, ----------<br>';
    result +=  `Date: ${date}<br>`;
    result +=  `Subject: ${subject}<br>`;

    if (from) {
      result +=  `From: ${from.name || ''} &#60;${from.email}&#62;<br>`;
    }
    if (to) {
      result +=  `To: ${to.name || ''} &#60;${to.email}&#62;<br>`;
    }
    result +=  '------------------------------<br>';

    return new Promise((resolve) => {

      MessageCodingModel.quoteMessage(html).then((body: string) => {
        resolve(`<br><br><div class="gmail_quote">
                  <div dir="ltr">${result}</div><br>
                  <div class="gmail_extra">${body}</div></div>`);
      });
    });
  }
  /**
   * Цитирование сообщения
   * @param {string} html
   * @returns {Promise<string>}
   */
  static quoteMessage(html: string): Promise<string> {
    return new Promise((resolve) => {

      MessageCodingBodyModel.parse(html).then((body: IMessageCodingBodyResult) => {
        const result = `<br><blockquote class="gmail_quote" style="${MessageCodingModel.styleBlockquote}">
                    <div ${body.style}>${body.html}</div>
                  </blockquote>`;
        resolve(result);
      });
    });
  }
}

/**
 * Если сообщения является HTML страницей
 */
export class MessageCodingBodyModel {

  regex = /<body([^>]*)>((.|[\n\r])*)<\/body>/g;

  html: string;

  static parse(html: string) {
    return new MessageCodingBodyModel(html).get();
  }

  constructor(html: string) {
    this.html = html;
  }

  get(): Promise<IMessageCodingBodyResult> {
    let m;
    return new Promise((resolve) => {
      if ((m = this.regex.exec(this.html)) !== null) {
        resolve({
          style: m[1],
          html: m[2]
        });
      } else {
        resolve({
          style: null,
          html: this.html
        });
      }
    });
  }
}
export interface IMessageCodingBodyResult {
  style: string;
  html: string;
}
