<div class="d-flex justify-content-center h-100">

  <div class="d-flex align-items-center" *ngIf="!idpiframeInitializationFailed else loading">
    <button type="button" class="btn btn-lg btn-outline-primary" (click)="signIn()">{{'Войти'|translate}}</button>
  </div>
</div>

<ng-template #loading>
  <div class="d-flex align-items-center">
    <div class=" flex-column justify-content-center">
      <p class="h2 text-danger">Cookies are not enabled in current environment.</p>
      <p class="lead text-center" *ngIf="isChrome">
        Go to Google Chrome settings in page.
      </p>
      <p class="h3 text-center" *ngIf="isChrome">chrome://settings/content/cookies</p>
    </div>
  </div>
</ng-template>
