import Message = gapi.client.gmail.gmail.Message;
import {HeaderRegExpModel} from './header.model';
import * as _ from 'lodash';
import {MessageCodingModel} from './message-coding.model';
import {IMessageAttachment, MessageAttachmentModel} from './message-attachment.model';

export class MessageDetailModel implements IMessageDetail {

  from: IMessageDetailHeader[] = [];
  to: IMessageDetailHeader[] = [];
  cc: IMessageDetailHeader[] = [];
  bcc: IMessageDetailHeader[] = [];

  bodyPlain: string = null;
  bodyHtml: string = null;
  date: string = new Date().toISOString();
  subject: string = null;
  messageId = '';
  references = '';
  label: {[label: string]: boolean} = {};
  /**
   * Отображать сообщения
   */
  show = true;
  /**
   * Являюсь отправителем Я
   */
  incomingEmail = false;
  /**
   * Количество вложенных файлов
   */
  countAttachment = 0;
  attachments: IMessageAttachment[] = [];

  read: IMessageRead = null;
  /**
   * @param {gapi.client.gmail.gmail.Message} args
   * @param {string[]} sendAs - список emails с которых пользователь может отправлять письма
   */
  constructor(args: Message, sendAs: string[] = []) {

    this.scan(args.payload || {});

    const headers: any = {};

    if (args.labelIds) {
      args.labelIds.forEach((label) => {
        this.label[label.toLowerCase()] = true;
      });
    }

    if (args.payload) {
      args.payload.headers.forEach((header) => {
        headers[header.name] = header.value;
      });
    }

    this.from = HeaderRegExpModel.getListMail(headers['From'] || '');
    this.to = HeaderRegExpModel.getListMail(headers['To'] || '');
    this.cc = HeaderRegExpModel.getListMail(headers['Cc'] || '');
    this.bcc = HeaderRegExpModel.getListMail(headers['Bcc'] || '');
    /**
     * Являюсь отправителем Я
     */
    this.from.forEach((item) => {
      if (!this.incomingEmail) {
        this.incomingEmail = sendAs.indexOf(item.email) > -1;
      }
    });

    this.subject = headers['Subject'] || null;
    this.date = headers['Date'] || null;
    this.messageId = headers['Message-Id'] || headers['Message-ID'] || null;
    this.references = headers['References'] || null;
  }

  /**
   * Разбираем сообщения
   * @param part
   */
  private scan(part: any) {

    if (part.parts) {

      part.parts.forEach((item: any) => {
        this.scan(item);
      });
    } else if (part.filename && part.filename.length || _.startsWith(part.mimeType, 'image/')) {
      // если названия файла отсутствует
      if (!part.filename.length) {
        part.filename = new Date().getTime() + '.' + part.mimeType.replace('image/', '');
      }

      const attachment = new MessageAttachmentModel(part);
      /**
       * Если вложения является картинкой в тексте письма
       * типа (src="cid:logo.png")
       * то countAttachment не увеличиваем
       */
      // console.log(attachment)
      // if (!attachment.headers['Content-ID']) {
        this.countAttachment++;
      // } else {
      //   attachment.isHidden = true;
      // }
      attachment.isHidden = false;
      this.attachments.push(attachment);

    } else if (part.body && part.body.size) {
      const body = _.get(part, 'body.data', '');
      switch (part.mimeType) {
        case 'text/plain':
          this.decodeBody(body, (bodyPlain: string) => {
            this.bodyPlain = bodyPlain;
          });
          break;
        case 'text/html':
          this.decodeBody(body, (bodyHtml: string) => {
            this.bodyHtml = bodyHtml;
          });
          break;
      }
    }
  }

  /**
   * Декодируем тело сообщения
   * @param {string} body сообщение закодированное в base64
   * @param {Function} done
   */
  private decodeBody(body: string, done: Function) {

    const data = MessageCodingModel.decode(body);

    if (!MessageCodingModel.isHtml(data)) {
      done(MessageCodingModel.plainToHtml(data));
    } else {
      done(data);
    }
  }
}

export interface IMessageDetailHeader {
  name: string;
  email: string;
}
export interface IListMessagesResponseExp {
  /**
   * List of messages.
   */
  messages?: IMessageExp[];
  /**
   * Token to retrieve the next page of results in the list.
   */
  nextPageToken?: string;
  /**
   * Estimated total number of results.
   */
  resultSizeEstimate?: number;
}
export interface IListDraftsResponseExp {
  /**
   * List of messages.
   */
  drafts: IDraft[];
  messages: IMessageExp[];
  /**
   * Token to retrieve the next page of results in the list.
   */
  nextPageToken?: string;
  /**
   * Estimated total number of results.
   */
  resultSizeEstimate?: number;
}

export interface IMessageExp extends Message {
  detail: IMessageDetail;
  storyIsOpen?: boolean;
  srcdoc?: any;
  draftId?: string;
}

export interface IDraft {
  /**
   * ID черновика
   */
  id: string;
  message: {
    /**
     * ID сообщения
     */
    id: string,
    threadId: string
  };
}

export interface IMessageDetail {

  from: IMessageDetailHeader[];
  to: IMessageDetailHeader[];
  cc: IMessageDetailHeader[];
  bcc: IMessageDetailHeader[];

  /**
   * Варианты письма
   */
  bodyPlain: string;
  bodyHtml: string;

  date: string;
  subject: string;
  messageId: string;
  references: string;
  label: {[label: string]: boolean};
  /**
   * Отображать сообщения
   */
  show: boolean;
  /**
   * Являюсь отправителем Я
   */
  incomingEmail: boolean;
  /**
   * Количество вложенных файлов
   */
  countAttachment: number;
  attachments: IMessageAttachment[];

  read?: IMessageRead;
}

/**
 * Сообщения прочитано,
 * получаем с сервера после того как клиент откроет письмо
 */
export interface IMessageRead {
  emailId: string;
  /**
   * @deprecated не используется в DB
   */
  userAgent?: string;
  openedAt: number;
  createdAt: number;
}
