import {FormControl} from '@angular/forms';
import {Base64} from 'js-base64';

export class TagInputModel {

  static regExpHeader(from: string) {
    return new RegExp('^(.+\\s)?([<(]?([a-zA-Z0-9_.+-]{1,50}@[a-zA-Z0-9.-]+\\.[a-zA-Z0-9-]{1,10})[)>]?)$', 'g').exec(from);
  }

  static toUTF8(str: string, removeQuotes: boolean = true): string {
    if (removeQuotes) {
      str = TagInputModel.removeQuotes(str);
    }

    return `=?UTF-8?B?${Base64.encode(str.trim())}?=`;
  }

  static removeQuotes(str: string): string {
    return str.replace(/("|')/g, '').trim();
  }

  /**
   * валидация Email
   * @param {FormControl} control
   * @returns {any}
   */
  static emailValidator(control: FormControl) {
    // const regexEmail = /^(.+\s)?([<(]?([a-zA-Z0-9_\-.]{1,50}@[a-zA-Z0-9.-]+\.[a-zA-Z0-9]{1,10})[)>]?)$/gm;
    const regexEmail = /(.+\s)?([<(]?([a-zA-Z0-9_\-.]{1,50}@[a-zA-Z0-9.-]+\.[a-zA-Z0-9]{1,10})[)>]?)/gm;

    if (!regexEmail.test(control.value)) {
      return {
        'emailValidator': true
      };
    }

    return null;
  }

  static toTagModel(value: string, encode: boolean = true): ITagModel | string {
    const res = TagInputModel.regExpHeader(value.trim());

    if (res) {
      let val = `<${res[3].toLowerCase()}>`;
      if (encode && res[1]) {
        /**
         * кодируем имя пользователя для корректной обработки гуглом
         */
        val = `${TagInputModel.toUTF8(res[1])} <${res[3].toLowerCase()}>`;
      }
      return <ITagModel>{
        display: res[1] ? `${TagInputModel.removeQuotes(res[1])} (${res[3].toLowerCase()})` : res[3].toLowerCase(),
        value: val
      };
    } else {
      return value;
    }
  }
}
export interface ITagModel {
  display?: string;
  value?: string;
  readonly?: boolean;
}
