import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';

declare const Notification: any;
/**
 * Всплывающее уведомление
 */
@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {

  permission: TPermissionNotification;

  constructor(
    private router: Router
  ) {
    this.permission  = this.isSupported() ? Notification.permission : 'denied';
  }

  /**
   * Запрашивает разрешение у пользователя показать уведомления.
   */
  requestPermission() {
    if ('Notification' in window) {
      Notification.requestPermission((status: any) => this.permission = status);
    }
  }

  isSupported() {
    return 'Notification' in window;
  }

  /**
   * Выводим уведомления в браузере
   * @param title
   * @param options
   * @param isFocus
   * @param router
   */
  push(title: string, options?: IPushNotification, isFocus = true, router?: string): void {

    const selfWin = window;

    if (this.permission === 'granted') {

      this.create(title, options).subscribe((res) => {
        // console.log('----', res);
        if (res.event && res.event.type === 'show') {

        } else if (res.event && res.event.type === 'click') {

          // Закрываем уведомления при клике по нему
          res.notification.close();

          if (isFocus && !document.hasFocus() && 'focus' in window) {
             window.focus();

             if (router) {
               return this.router.navigateByUrl(router);
             }
          }
        }
      }, err => {
        console.log(err);
      });
    }
  }

  create(title: string, options?: IPushNotification): any {

    return new Observable((obs: any) => {

      if (!('Notification' in window)) {
        obs.error('Notifications are not available in this environment');
        obs.complete();
      }

      if (this.permission !== 'granted') {
        obs.error(`The user hasn't granted you permission to send push notifications`);
        obs.complete();
      }

      const n = new Notification(title, options);

      n.onshow = (e: any) => obs.next({notification: n, event: e});
      n.onclick = (e: any) => obs.next({notification: n, event: e});
      n.onerror = (e: any) => obs.error({notification: n, event: e});
      n.onclose = () => obs.complete();
    });
  }
}
/**
 * Экземпляр объекта Notification.
 * @inheritDoc https://developer.mozilla.org/ru/docs/Web/API/notification
 * @example https://web-push-book.gauntface.com/demos/notification-examples/
 */
export interface IPushNotification {
  /**
   * Основное тело (текст) уведомления, указывается в опциях как параметр конструктора.
   */
  body?: string;
  /**
   * URL изображения уведомления, указывается в опциях как параметр конструктора.
   */
  icon?: string;
  /**
   * Инденцификатор (id) уведомления, указывается в опциях как параметр конструктора.
   */
  tag?: string;
  /**
   * Возвращает копию информации уведомления.
   */
  data?: any;
  /**
   * Определяет должно ли новое пользовательское уведомление заменить предыдущее.
   */
  renotify?: boolean;
  /**
   * Указывает, должно ли уведомление сопровождаться со звуковым или вибро сигналом, не зависимо от настроек устройства.
   */
  silent?: boolean;
  /**
   * Определяет звуковой файл для воспроизведения при уведомлении, по умолчанию установлен системный звук.
   */
  sound?: string;
  /**
   * Определяет должен ли пользотельский дисплей включаться (если был выключен) при получении уведомления или нет
   */
  noscreen?: boolean;
  /**
   * Определяет должно ли уведомление быть "sticky", то есть не легко закрываемым.
   */
  sticky?: boolean;
  /**
   * Направление текста уведомления, указывается в опциях как параметр конструктора.
   */
  dir?: 'auto' | 'ltr' | 'rtl';
  /**
   * Код языка уведомления, указывается в опциях как параметр конструктора.
   */
  lang?: string;
  /**
   * Задает шаблон вибрации для устройств с вибро .
   */
  vibrate?: number[];

  buttons?: any;
}

/**
 * Права доступа для отображения уведомлений
 *
 * denied - (пользователь отказался от отображения уведомлений),
 * granted - (пользователь дал согласие на отображение уведомлений),
 * default - (выбор пользователя неизвестен и поэтому браузер будет действовать так, как если бы значение было denied ).
 */
export type TPermissionNotification = 'denied' | 'granted' | 'default';

/**
 * @example http://findicons.com/icon/499071/chat_emoticon_cross
 */
export const PushNotificationIcon = {

  mail: 'assets/icons/mail.png',
};
