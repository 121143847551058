import {crc32} from '../fn';

/**
 *
 */
export class DomStrModel {

  dom: Document = null;

  static textHtml(html: string) {
    return new DomStrModel(html);
  }

  constructor(
    private html
  ) {
    this.dom = new DOMParser().parseFromString(this.html || '', 'text/html');
  }

  /**
   * Вставляем подпись
   * @param signature
   * @param selector
   * @param isReplaceChild
   */
  addSignature(signature: string, selector: string = 'body', isReplaceChild: boolean = false) {

    const el =  document.createElement('div');
    el.className = 'gmail_signature';
    el.innerHTML = signature || '';

    const _selector = this.dom.querySelector(selector);

    if (_selector) {
      if (isReplaceChild) {
        _selector.className = 'gmail_signature';
        _selector.innerHTML = signature || '';
      } else {
        _selector.appendChild(el);
      }
    }
    return this;
  }

  /**
   * Перемещаем все теги [style] в низ страницы
   */
  combineStyles() {
    const keyStyle: any = {};
    let allKey;
      for (allKey in this.dom.all) { // tslint:disable-line
        switch (this.dom.all[allKey].nodeName) { // tslint:disable-line
          case 'STYLE':
            keyStyle[crc32(this.dom.all[allKey].innerHTML)] = this.dom.all[allKey].innerHTML; // tslint:disable-line
            this.dom.all[allKey].remove(); // tslint:disable-line
            break;
          case 'SCRIPT':
            this.dom.all[allKey].remove(); // tslint:disable-line
            break;
        }
      }

      if (Object.values(keyStyle).length) {
        Object.values(keyStyle).forEach((value: string) => {
          const style =  document.createElement('style');
          style.type = 'text/css'; // tslint:disable-line
          style.innerHTML = value;
          this.dom.body.appendChild(style);
        });
      }

      return this;
  }

  getHtml(): string {
    return this.dom.documentElement.outerHTML || '';
  }

  getInnerText() {
    return this.dom.documentElement.innerText || '';
  }

  getInnerHTML() {
    return this.dom.documentElement.innerHTML || '';
  }
}
