/**
 * Вложения письма
 */
import {isArray} from 'rxjs/internal-compatibility';

export class MessageAttachmentModel {

  partId: string;
  mimeType: string;
  filename: string;
  headers: {[key: string]: string} = {};
  attachmentId: string;
  data: string;
  size: number;
  isHidden = false;
  /**
   * Оригинальный part
   */
  _origin: any = null;

  constructor(part: any = {}) {

    this._origin = part;
    this.partId = part.partId || null;
    this.filename = part.filename || null;
    this.mimeType = part.mimeType || null;

    if (part.headers && isArray(part.headers)) {
      part.headers.forEach((header: {name: string, value: string}) => {
        this.headers[header.name] = header.value;
      });
    }
    this.attachmentId = part.body.attachmentId || null;
    this.data = part.body.data || null;
    this.size = part.size || part.body.size || null;
  }
}
export interface IMessageAttachment {
  partId: string;
  mimeType: string;
  filename: string;
  headers: {[key: string]: string};
  attachmentId: string;
  data: string;
  size: number;

  // скрить в списке вложений
  isHidden?: boolean;
}
