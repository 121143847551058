import {Injectable} from '@angular/core';
import {from, Observable, Observer, of} from 'rxjs';
import {map} from 'rxjs/operators';

/**
 * Локальная DB
 */
@Injectable({
  providedIn: 'root'
})
export class DbStorageService {

  private db: IStorage;

  constructor() {}

  diffTime(time: number): number {
    if (!time) {
      return 0;
    }

    return Math.abs(Math.ceil((time - Date.now()) / 1000));
  }

  getTable(table: string): Observable<any> {

    let db;
    if ('indexedDB' in window) {
      db = new IndexedDBStorage();
    } else {
      db = new MemoryStorage();
    }

    return db.table(table);
  }
}

class IndexedDBStorage implements IStorage {

  private name: string;

  constructor(
    private databaseName: string = 'ngDatabaseName'
  ) {}

  private getDb(version?: number, storeName?: string): Observable<IDBDatabase> {

    return Observable.create((observer: Observer<number>) => { // tslint:disable-line

      const req = version && version > 0 ? window.indexedDB.open(this.databaseName, version)
        : window.indexedDB.open(this.databaseName);

      req.onsuccess = (event) => {
        const db = (<any>event.target).result;
        observer.next(db);
        db.close();
        observer.complete();
      };

      req.onupgradeneeded = (e) => {

        const db = (<any>e.target).result;

        if (storeName && !db.objectStoreNames.contains(storeName)) {

          db.createObjectStore(storeName, {
            keyPath: 'id'
          });

          const transaction = (<any>e.target).transaction;

          transaction.oncomplete = (event) => {
            observer.next(db);
            db.close();
            observer.complete();
          };
        }
      };

      req.onblocked = (event) => observer.error('IndexedDB is blocked');
      req.onerror = (e: any) => observer.error(e.error);
    });
  }

  private getVersionOfDb(name: string): Observable<number> {

    return this.getDb().pipe(
      map(db => {
        if (!db.objectStoreNames.contains(this.name)) {
          return db.version + 1;
        } else {
          return db.version;
        }
      })
    );
  }

  table(name: string): Observable<IndexedDBStorage> {
    this.name = name;

    return Observable.create((observer: Observer<IndexedDBStorage>) => { // tslint:disable-line

      this.getVersionOfDb(name).subscribe((version) => {

        this.getDb(version, name).subscribe(db => {
          observer.next(this);
          observer.complete();
        });
      });
    });
  }

  all(): Observable<any> {

    return Observable.create((observer: Observer<any>) => { // tslint:disable-line
      this.getDb().subscribe(db => {

        const result: any[] = [];

        const req = db.transaction(this.name, 'readonly').objectStore(this.name).openCursor();

        req.onsuccess = (event: any) => {
          const res = (<any>event.target).result;

          if (res) {
            result.push(res.value);
            res.continue();
          } else {

            observer.next(result);
            observer.complete();
          }

        };
        req.onerror = (e: any) => observer.error(e.error);
      });
    });
  }

  get(key: string | number): Observable<any> {
    key = (typeof key === 'number') ? key.toString() : key;

    return Observable.create((observer: Observer<any>) => { // tslint:disable-line
      this.getDb().subscribe(db => {
        const req: any = db.transaction(this.name, 'readonly').objectStore(this.name).get(key);
        req.onerror = (e: any) => observer.error(e.error);
        req.onsuccess = (e: any) => {
          observer.next(req.result);
          observer.complete();
        };
      });
    });
  }

  clear(): Observable<IStorage> {
    return Observable.create((observer: Observer<IStorage>) => { // tslint:disable-line
      this.getDb().subscribe(db => {
        const req = db.transaction(this.name, 'readwrite').objectStore(this.name).clear();
        req.onerror = (e: any) => observer.error(e.error);
        req.onsuccess = (e: any) => {
          observer.next(this);
          observer.complete();
        };
      });
    });
  }

  put(value: IItem): Observable<any> {
    if (!value.id) {
      value.id = Math.random().toString(36).substring(7);
    } else {
      value.id = (typeof value.id === 'number') ? value.id.toString() : value.id;
    }

    return Observable.create((observer: Observer<any>) => { // tslint:disable-line
      this.getDb().subscribe(db => {
        const req = db.transaction(this.name, 'readwrite')
          .objectStore(this.name)
          .put(value);
        req.onerror = (e: any) => {
          observer.error(e.error);
        };
        req.onsuccess = (e: any) => {
          observer.next(value);
          observer.complete();
        };
      });
    });
  }

  getDenseBatch(keys: string[]): Observable<any> {
    return Observable.create((observer: Observer<any>) => { // tslint:disable-line
      this.getDb().subscribe(db => {
        const set = keys.sort();
        let i = 0;
        const req = db.transaction(this.name).objectStore(this.name)
          .openCursor();
        req.onsuccess = (event: any) => {
          // let cursor = (<any>event.target).result;
          const cursor = <IDBCursorWithValue>(<any>event.target).result;
          if (!cursor) {
            observer.complete();
            return;
          }
          const key = cursor.key;
          while (key > set[i]) {
            // The cursor has passed beyond this key. Check next.
            ++i;
            if (i === set.length) {
              // There is no next. Stop searching.
              observer.complete();
              return;
            }
          }
          if (key === set[i]) {
            // The current cursor value should be included and we should continue
            // a single step in case next item has the same key or possibly our
            // next key in set.
            // observer.next(cursor.value);
            observer.next(cursor);
            cursor.continue();
          } else {
            // cursor.key not yet at set[i]. Forward cursor to the next key to hunt for.
            cursor.continue(set[i]);
          }
        };
        req.onerror = (e: any) => observer.error(e.error);
      });
    });
  }
}

class MemoryStorage implements IStorage {

  private storage: { [key: string]: any } = {};

  table(name: string): Observable<MemoryStorage> {
    return of(this);
  }

  get(key: string | number): Observable<any> {
    // return Observable.of(this.storage[key]);
    key = (typeof key === 'number') ? key.toString() : key;

    return of(this.storage[key]);
  }

  clear(): Observable<any> {
    this.storage = {};
    // return Observable.empty<any>();
    return of();
  }

  put(value: IItem): Observable<any> {
    if (!value.id) {
      value.id = Math.random().toString(36).substring(7);
    }
    this.storage[value.id] = value;
    return of(value);
  }

  getDenseBatch(keys: string[]): Observable<any> {
    return from(keys.map(x => this.storage[x]));
  }

  all(): Observable<any> {
    return from(Object.keys(this.storage).map(x => this.storage[x]));
  }
}

export interface IStorage {
  // Initial method to create storage
  table(name: string): Observable<IStorage>;

  // Получить значение ключу
  get(key: string | number): Observable<any>;

  // Clear/remove все данные в хранилище
  clear(): Observable<any>;

  /**
   * Поместите определенную ценность в хранилище
   * @example
   *  this.localDatabase.db.table('test2').subscribe((db) => {
   *    db.put({ id: key1, value: value1 }).subscribe(() => {
   *      db.all().subscribe(items => {
   *
   *      });
   *    });
   * });
   * @param value
   */
  put(value: IItem): Observable<any>;

  // Получить все значения с помощью набора ключей
  getDenseBatch(keys: string[]): Observable<any>;

  // Получить все значения из хранилища
  all(): Observable<any>;
}

interface IItem {
  id?: string | number;
  value: any;
}
