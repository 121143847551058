import {ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, OnInit, ViewEncapsulation} from '@angular/core';

/**
 * @example
 * `
 *  <ng-progress-bar></ng-progress-bar>
 *  <lib-progress-bar></lib-progress-bar>
 *
 *  <ng-progress-bar mode="indeterminate"></ng-progress-bar>
 *  <lib-progress-bar mode="indeterminate"></lib-progress-bar>
 */
let progressbarId = 1;

@Component({
  selector: 'lib-progress-bar, ng-progress-bar', // tslint:disable-line
  encapsulation: ViewEncapsulation.None,
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  preserveWhitespaces: false,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressBarComponent implements OnInit {

  @Input() mode: 'determinate' | 'indeterminate' | 'buffer' | 'query' = 'indeterminate';

  @HostBinding('class.progress-bar') classProgressBar = true;
  @HostBinding('attr.role') attrRole = this.mode;
  @HostBinding('attr.role') attrProgressbar = 'progressbar';
  @HostBinding('attr.aria-valuemin') attrAriaValuemin = 0;
  @HostBinding('attr.aria-valuemax') attrAriaValuemax = 100;


  @HostBinding('class.primary') get classPrimary() {
    return this.color === 'primary';
  }
  @HostBinding('class.accent') get classAccent() {
    return this.color === 'accent';
  }
  @HostBinding('class.warn') get classWarn() {
    return this.color === 'warn';
  }

  @Input()
  get color(): ThemePalette {
    return this._color;
  }
  set color(value: ThemePalette) {
    const colorPalette = value || 'primary';

    if (colorPalette !== this._color) {
      if (this._color) {
        this._elementRef.nativeElement.classList.remove(`${this._color}`);
      }
      if (colorPalette) {
        this._elementRef.nativeElement.classList.add(`${colorPalette}`);
      }

      this._color = colorPalette;
    }
  }
  private _color: ThemePalette = 'primary';

  @Input()
  get bufferValue(): number {
    return this._bufferValue;
  }
  set bufferValue(v: number) {
    this._bufferValue = this.clamp(v || 0);
  }
  private _bufferValue = 0;

  @Input()
  get value(): number {
    return this._value;
  }
  set value(v: number) {
    this._value = this.clamp(v || 0);
  }
  private _value = 0;

  @HostBinding('attr.aria-valuenow') attrAriaValuenow = this.value;

  progressbarId = `progress-bar-${progressbarId++}`;


  constructor(
    public _elementRef: ElementRef
  ) {}

  ngOnInit(): void {}

  _primaryTransform() {
    const scale = this.value / 100;
    return {transform: `scaleX(${scale})`};
  }

  _bufferTransform() {
    if (this.mode === 'buffer') {
      const scale = this.bufferValue / 100;
      return {transform: `scaleX(${scale})`};
    }
  }

  private clamp(v: number, min = 0, max = 100) {
    return Math.max(min, Math.min(max, v));
  }
}

/** Possible color palette values. */
type ThemePalette = 'primary' | 'accent' | 'warn' | undefined;
interface CanColor {
  /** Theme color palette for the component. */
  color: ThemePalette;
}

