export class MessagePaginationModel {

  private pagin: string[] = [];

  to(pageToken?: string): void {
    if (pageToken && this.pagin.indexOf(pageToken) === -1) {
      this.pagin.push(pageToken);
    }
  }

  isPrev(pageToken: string): boolean {

    if (this.pagin.length > 0) {

      if (this.pagin.indexOf(pageToken) > 0 || !pageToken) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  prev(pageToken?: string): string {

    if (!pageToken && !this.pagin.length) {
      return null;
    }

    const page = this.pagin.indexOf(pageToken);

    if (page === -1) {

      if (this.pagin.length - 2 >= 0) {
        return this.pagin[this.pagin.length - 2];
      }
      return '';
    } else if (page === 0) {
      return null;
    } else {
      return this.pagin[page - 2];
    }
  }
}
