import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

import {ISetting, ISettingGmail, SettingModel} from '../model';

@Injectable({
  providedIn: 'root'
})
export class SettingService {

  param: ISetting;
  auth: any;

  paramGMail: BehaviorSubject<ISettingGmail[]> = new BehaviorSubject<ISettingGmail[]>([]);

  constructor() {
    this.param = new SettingModel();
  }

  getLang() {
    let lang = 'ru';
    if (this.auth && this.auth.language) {
      lang = this.auth.language;
    }
    return lang;
  }
  /**
   * Замена перемменых в тексте
   * @param template шаблон
   *
   *  [EMAIL]
   *  [FULLNAME] - Имя Фамилия
   *  [FIRSTNAME] - Имя
   *  [LASTNAME] - Фамилия
   *  [PHONE] - Телефон
   *  [POSITION] - Должность
   *  [SIGNATURE] - Подпись
   */
  varTemplate(template: string) {

    if (this.auth) {
      const email = this.auth.email || '';
      const firstName = this.auth.firstName || '';
      // const fullName = this.auth.fullName || '';
      const lastName = this.auth.lastName || '';
      const phone = this.auth.phone || '';
      const position = this.auth.position || '';
      const signature = this.auth.signature || this.param.signature || '';

      return template.replace('[EMAIL]', email.trim())
        .replace('[FIRSTNAME]', firstName.trim())
        .replace('[LASTNAME]', lastName.trim())
        .replace('[PHONE]', phone.trim())
        .replace('[POSITION]', position.trim())
        .replace('[FULLNAME]', `${firstName.trim()} ${lastName.trim()}`)
        .replace('[SIGNATURE]', `<div class="gmail_signature">${signature}</div>`);
    } else {
      return template;
    }
  }
}
