import {Injectable, OnInit} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanLoad, RouterStateSnapshot} from '@angular/router';
import {GApiService} from '../service';
import {Route} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class GapiCanGuard implements CanLoad, CanActivate {

  constructor(
    private gApi: GApiService
  ) {
  }

  /**
   * Проверяем прошла ли инициализация GAPI
   */
  canLoad(route: Route): Promise<boolean> {
    return this.gApi.init().then((status: boolean) => {
      return status;
    });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.canLoad(null);
  }
}
