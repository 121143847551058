/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./permissions.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./permissions.component";
var styles_PermissionsComponent = [i0.styles];
var RenderType_PermissionsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PermissionsComponent, data: {} });
export { RenderType_PermissionsComponent as RenderType_PermissionsComponent };
export function View_PermissionsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 20, "div", [["class", "error"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 19, "div", [["class", "container-floud"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 18, "div", [["class", "col-xs-12 ground-color text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 15, "div", [["class", "container-error-404"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "clip"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "shadow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "digit thirdDigit"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["4"])), (_l()(), i1.ɵeld(8, 0, null, null, 3, "div", [["class", "clip"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "shadow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [["class", "digit secondDigit"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["0"])), (_l()(), i1.ɵeld(12, 0, null, null, 3, "div", [["class", "clip"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 2, "div", [["class", "shadow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "span", [["class", "digit firstDigit"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["1"])), (_l()(), i1.ɵeld(16, 0, null, null, 2, "div", [["class", "msg"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["OH!"])), (_l()(), i1.ɵeld(18, 0, null, null, 0, "span", [["class", "triangle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "h2", [["class", "h1"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Access is denied"]))], null, null); }
export function View_PermissionsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "page-permissions", [], null, null, null, View_PermissionsComponent_0, RenderType_PermissionsComponent)), i1.ɵdid(1, 114688, null, 0, i2.PermissionsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PermissionsComponentNgFactory = i1.ɵccf("page-permissions", i2.PermissionsComponent, View_PermissionsComponent_Host_0, {}, {}, []);
export { PermissionsComponentNgFactory as PermissionsComponentNgFactory };
