/**
 * @deprecated
 */
if ('FroalaEditor' in (window as any).$) {
  (window as any).$.FroalaEditor.DefineIcon('extend_toolbar_buttons_dropdown', {NAME: 'cog'});
  (window as any).$.FroalaEditor.RegisterCommand('extend_toolbar_buttons_dropdown', {
    title: 'Advanced options',
    type: 'dropdown',
    focus: false,
    undo: false,
    refreshAfterCallback: true,
    options: {
      outdent: '<i class=\'fa fa-outdent\'></i> Decrease Indent',
      indent: '<i class=\'fa fa-indent\'></i> Increase Indent',
      insertHR: '<i class=\'fa fa-minus\'></i> Insert Horizontal Line',
      clearFormatting: '<i class=\'fa fa-eraser\'></i> Clear Formatting',
      // 'fullscreen': '<i class=\'fa fa-expand\'></i> Fullscreen'
    },
    callback: function(cmd, val) {
      if (val === 'fullscreen') {
        this.fullscreen.toggle();
      } else if (val === 'clearFormatting') {
        this.commands.clearFormatting();
      } else if (val === 'insertHR') {
        this.commands.insertHR();
      } else if (val === 'outdent') {
        this.commands.outdent();
      } else if (val === 'indent') {
        this.commands.indent();
      }
    }
  });
}

