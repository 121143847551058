import { of } from 'rxjs';
import { ContactsService } from './contacts.service';
import * as i0 from "@angular/core";
import * as i1 from "./contacts.service";
/**
 * Подсказки Emails
 */
export class EmailTipService {
    constructor(contacts) {
        this.contacts = contacts;
        this.items = [];
        this.list = {};
        this.contactsList = {};
        /**
         * Поиск вводимого email в контактах оператора
         * @param text вводимый текст в форме
         */
        this.autocompleteEmailItems = (text) => {
            return of(Object.values(this.list).filter((v) => v.indexOf(text) > -1).slice(0, 5));
        };
        // tslint:disable-next-line
        this.contacts.list.subscribe((contacts) => {
            this.contactsList = contacts;
            let email;
            for (email in contacts) {
                if (contacts[email].length) {
                    this.list[email] = `${contacts[email]} <${email}>`;
                }
                else {
                    this.list[email] = email;
                }
            }
        });
    }
    has(email) {
        return this.items.indexOf(email) > -1;
    }
    set(items) {
        this.items = this.items.concat(items);
        items.forEach((email) => {
            if (!this.list[email]) {
                this.list[email] = email;
            }
        });
    }
    /**
     * Автодополнения форм с контактов
     */
    searchAutoComplete(text) {
        if (!text.length) {
            return [];
        }
        const result = new Set();
        // tslint:disable-next-line
        for (let email in this.contactsList) {
            if (this.contactsList[email].indexOf(text) > -1) {
                result.add(this.contactsList[email]);
                // result.add(`${this.contactsList[email]} <${email}>`);
            }
            if (email.indexOf(text) > -1) {
                result.add(email);
            }
        }
        // @ts-ignore
        return Array.from(result.values()).sort().slice(0, 5);
    }
}
EmailTipService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EmailTipService_Factory() { return new EmailTipService(i0.ɵɵinject(i1.ContactsService)); }, token: EmailTipService, providedIn: "root" });
