export const environment = {
  production: true,
  // socketUrl: 'wss://mm-track.binotel.com/u/ws?u=',
  socketUrl: (window.location.hostname === 'gmail.binotel.space') ? 'wss://gmail.binotel.space/u/ws?u=' : 'wss://mm.binotel.com/u/ws?u=',
  // socketUrl: 'wss://gmail.binotel.space/u/ws?u=',
  // tslint:disable-next-line
  actionUrl: (window.location.hostname === 'gmail.binotel.space') ? 'https://gmail.binotel.space/api/' : 'https://mm-track.binotel.com/api/',
  // actionUrl: 'https://gmail.binotel.space/api/',
  local: false
};
