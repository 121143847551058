/* tslint:disable */
import {Base64} from 'js-base64';
import {IAttachmentSend} from './attachment.model';
import {MessageCodingModel} from './message-coding.model';
import {AppConfig} from '../../app.config';
const frIframeStyleCss = require('../../../assets/fr-iframe-style.css');
/**
 * @link https://github.com/RyunDoKim/gmail-parser/blob/master/index.js
 * @link https://github.com/mathiasbynens/q-encoding
 */
export class MessageSendModel implements IMessageSendForm {

  private mimeVersion = 'MIME-Version: 1.0';
  private multipartMixed = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  private multipartAlternative = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

  attachments = [];

  listTo: IMessageSendFormRecipient[] = [];
  listCc: IMessageSendFormRecipient[] = [];
  listBcc: IMessageSendFormRecipient[] = [];
  /**
   * Тема сообщения
   */
  subject: string;
  body: string;

  references: string;
  /**
   * Ответ на сообщения
   * {string} inReplyTo заголовок Message-ID с сообщения на которое отвечаем
   */
  inReplyTo: string;
  from: any;

  textPlain: string;
  textHtml: string;

  constructor(arg: IMessageSendForm) {
    Object.assign(this, arg);

    if (this.textHtml) {
      this.textHtml = this.textHtml.replace('</body>', `<style class="id-css-bem-is" type="text/css">${frIframeStyleCss}</style></body>`);
    }
  }

  addAttachments(attachments: IAttachmentSend[]): MessageSendModel {
    this.attachments = attachments;
    return this;
  }

  toInline(...arg: string[]): string {
    return arg.join('');
  }

  /**
   * Кодирукм email
   * если строка для отправки содержыт имя получателя (Имя Получателя <ex@gmail.com>)
   * то кодируем такой адрес для коректного отображения в google (=?UTF-8?B?Base64(Имя Получателя)?= <ex@gmail.com>)
   * @param value
   */
  emailToUtf8(value: string) {
    if (value.indexOf(' <') > -1) {
      let m;
      let m2;
      if ((m = /(.+)<(.+)>/g.exec(value)) !== null) {
        if (m[1] && m[1].trim().length && (m2 = /([a-zA-Z0-9]+)/g.exec(m[1].trim())) !== null) {
          return `"${m[1].trim()}" <${m[2]}>`;
        } else if (m[1] && m[1].trim().length) {
          return `=?UTF-8?B?${Base64.encode(m[1].trim())}?= <${m[2]}>`;
        } else {
          return value;
        }
      } else {
        return value;
      }
    } else {
      return value;
    }
  }
  /**
   * Формируем тело запроса
   * @param {boolean} isEncode
   * @param {boolean} isDraft - сообщения для черновика
   * @returns {Promise<string>}
   */
  async send(isEncode: boolean = true, isDraft?: boolean): Promise<string> {
    const nl = '\n';
    let msg = '';

    if (!isDraft) {
      msg = await this.toInline(this.mimeVersion, nl);
    }

    if (this.subject && this.subject.length) {
      msg += await this.toInline('Subject: =?UTF-8?B?', Base64.encode(this.subject), '?=', nl);
    } else if (!isDraft) {
      throw new Error('not.email.subject');
    }

    /**
     * запросить у клиента отправить письмо про прочтения письма
     * https://tools.ietf.org/html/rfc3798
     */
    // msg += this.toInline('Disposition-Notification-To: ', '1074746@gmail.com', nl);

    if (this.inReplyTo && this.inReplyTo.length) {
      msg += await this.toInline('In-Reply-To: ', this.inReplyTo.trim(), nl);
    }

    if (this.references && this.references.length) {
      msg += await this.toInline('References: ', this.references.trim());
      if (this.inReplyTo && this.references !== this.inReplyTo) {
        msg += await this.toInline(' ', this.inReplyTo.trim());
      }
      msg += nl;
    }

    if (this.from && typeof this.from  === 'string' && this.from.length) {
      msg += await this.toInline('From: ', this.from.trim(), nl);
    } else if (this.from && this.from.name && this.from.value && !isDraft) {
      msg += await this.toInline( `From: =?UTF-8?B?${Base64.encode(this.from.name)}?= <${this.from.value}>`, nl);
    } else if (!isDraft) {
      throw new Error('not.email.from');
    }

    // Получатели
    if (this.listTo) {
      msg += await this.toInline('To: ');
      this.listTo.forEach((item: IMessageSendFormRecipient, i) => {
        // msg += this.toInline((i === 0 ? '' : ', '), item.value || item.toString());
        msg += this.toInline((i === 0 ? '' : ', '), this.emailToUtf8(item.value));
      });
      msg += this.toInline(nl);
    } else if (!isDraft) {
      throw new Error('not.email.listTo');
    }
    // Копия
    if (this.listCc && this.listCc.length) {
      msg += this.toInline('CC: ');
      this.listCc.forEach((item: IMessageSendFormRecipient, i) => {
        // msg += this.toInline((i === 0 ? '' : ', '), item.value || item.toString());
        msg += this.toInline((i === 0 ? '' : ', '), this.emailToUtf8(item.value));
      });
      msg += this.toInline(nl);
    }
    // Скрытая копия
    if (this.listBcc && this.listBcc.length) {
      msg += this.toInline('BCC: ');
      this.listBcc.forEach((item: IMessageSendFormRecipient, i) => {
        // msg += this.toInline((i === 0 ? '' : ', '), item.value || item.toString());
        msg += this.toInline((i === 0 ? '' : ', '), this.emailToUtf8(item.value));
      });
      msg += this.toInline(nl);
    }

    if (this.attachments.length) {
      msg += 'Content-Type: multipart/mixed; boundary="' + this.multipartMixed + '"' + nl + nl;

      msg += await this.toInline('--', this.multipartMixed, nl);
    }

    msg += 'Content-Type: multipart/alternative; boundary="' + this.multipartAlternative + '"' + nl + nl;

    if (this.textPlain) {
      msg += this.toInline('--', this.multipartAlternative, nl);
      msg += 'Content-Type: text/plain; charset="UTF-8"' + nl;
      msg += this.toInline('Content-Transfer-Encoding: base64', nl);
      msg += this.toInline('Content-Disposition: inline', nl, nl);
      msg += await this.toInline(Base64.encode(this.textPlain), nl);
    }
    if (this.textHtml) {
      msg += this.toInline('--', this.multipartAlternative, nl);
      msg += 'Content-Type: text/html; charset="UTF-8"' + nl;
      msg += this.toInline('Content-Transfer-Encoding: base64', nl);
      msg += this.toInline('Content-Disposition: inline', nl, nl);
      msg += await this.toInline(Base64.encode(this.textHtml), nl);
    }

    msg += this.toInline('--', this.multipartAlternative, '--', nl);

    // Вложения
    // if (this.attachments.length && !isDraft) {
    if (this.attachments.length) {

      await this.attachments.forEach((attachment: IAttachmentSend) => {

        msg += this.toInline('--', this.multipartMixed, nl);
        msg += this.toInline(attachment.mime, nl, nl);
      });

      msg += this.toInline('--', this.multipartMixed, '--', nl);
    }

    if (isEncode) {
      return msg;
    } else {
      return MessageCodingModel.encode(msg);
    }
  }
}

/**
 * Форма отправки сообщения
 */
export interface IMessageSendForm {
  listTo: IMessageSendFormRecipient[];
  listCc: IMessageSendFormRecipient[];
  listBcc: IMessageSendFormRecipient[];
  subject: string;
  body: string;

  references?: string;
  inReplyTo?: string;
  from?: string;
  textPlain: string;
  textHtml: string;
}
export interface IMessageSendFormRecipient {
  display: string;
  value: string;
}
export interface IMessageSendFormResponse {
  id: string;
  threadId: string;
  labelIds: string[];
}
