import { Injectable } from '@angular/core';
import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
// tslint:disable:max-line-length
/**
 * @inheritDoc http://jasonwatmore.com/post/2018/05/23/angular-6-jwt-authentication-example-tutorial
 * @inheritDoc https://medium.com/@ryanchenkie_40935/angular-authentication-using-the-http-client-and-http-interceptors-2f9d1540eb8
 */
@Injectable()
export class HttpJwtInterceptorService implements HttpInterceptor  {

  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // add authorization header with jwt token if available
    // let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    // if (currentUser && currentUser.token) {
    //   request = request.clone({
    //     setHeaders: {
    //       Authorization: `Bearer ${currentUser.token}`
    //     }
    //   });
    //
    // } else {
    //   request = request.clone({
    //     setHeaders: {
    //       Authorization: `Bearer ya29.GlwPBm7fbJG5SO4DbTrLH2GBc7GqXRmR2nQn8RUc_0uYA3uXmdmJiITieW55gpy2KnWG3cidxWRJomBYQ12Va2qqivO_30YdUG9iHUhZkkle2WBCP_mHCRX2Xa86bA`
    //     }
    //   });
    //   console.log('------', request)
    // }

    // request = request.clone();

    return next.handle(request);
  }
}
