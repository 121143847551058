import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'page-permissions',//tslint:disable-line
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.scss']
})
export class PermissionsComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
