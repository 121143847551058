import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable()
export class HttpErrorInterceptorService implements HttpInterceptor {
  constructor() {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(catchError(reason => {
      console.log([reason]);
      if (reason instanceof HttpErrorResponse) {
        if ([401, 403].includes(reason.status)) {
          // auto logout if 401 response returned from api
          // this.authenticationService.logout();
          // location.reload(true);
          if (reason.url.startsWith('https://www.googleapis.com')) {

          }
        } else if (reason.status === 0) {
          return throwError(reason);
        }  else if (reason.status >= 500) {
          return throwError(reason);
        }  else if (reason.status >= 400) {
          return throwError(reason);
        } else if (reason.status !== 200) {
          console.log('---HttpError:', request);
        }
      }

      const error = reason.error.message || reason.statusText;
      return throwError(error);
    }));
  }
}
