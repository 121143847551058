import { EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { DEFAULT_ICONS } from '../consts/default-icons.const';
import * as i0 from "@angular/core";
// tslint:disable:max-line-length
export class NotificationsService {
    constructor(globalOptions) {
        this.globalOptions = globalOptions;
        this.emitter = new Subject();
        this.icons = DEFAULT_ICONS;
    }
    set(notification, to) {
        notification.id = notification.override && notification.override.id ? notification.override.id : Math.random().toString(36).substring(3);
        notification.click = new EventEmitter();
        notification.timeoutEnd = new EventEmitter();
        this.emitter.next({ command: 'set', notification: notification, add: to });
        return notification;
    }
    success(title = '', content = '', override) {
        return this.set({ title: title, content: content || '', type: 'success', icon: this.icons.success, override: override }, true);
    }
    error(title = '', content = '', override) {
        return this.set({ title: title, content: content || '', type: 'error', icon: this.icons.error, override: override }, true);
    }
    alert(title = '', content = '', override) {
        return this.set({ title: title, content: content || '', type: 'alert', icon: this.icons.alert, override: override }, true);
    }
    info(title = '', content = '', override) {
        return this.set({ title: title, content: content || '', type: 'info', icon: this.icons.info, override: override }, true);
    }
    warn(title = '', content = '', override) {
        return this.set({ title: title, content: content || '', type: 'warn', icon: this.icons.warn, override: override }, true);
    }
    bare(title = '', content = '', override) {
        return this.set({ title: title, content: content || '', type: 'bare', icon: 'bare', override: override }, true);
    }
    // With type method
    create(title = '', content = '', type = 'success', override) {
        return this.set({ title: title, content: content, type: type, icon: this.icons[type], override: override }, true);
    }
    // HTML Notification method
    html(html, type = 'success', override, icon = 'bare') {
        return this.set({ html: html, type: type, icon: this.icons[icon], override: override }, true);
    }
    // Remove all notifications method
    remove(id) {
        if (id) {
            this.emitter.next({ command: 'clean', id: id });
        }
        else {
            this.emitter.next({ command: 'cleanAll' });
        }
    }
}
NotificationsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationsService_Factory() { return new NotificationsService(i0.ɵɵinject("NOTIFICATION_OPTION")); }, token: NotificationsService, providedIn: "root" });
