import {IMessageDetailHeader} from './message.model';

export class HeaderRegExpModel {

  static removeQuotes(str: string): string {
    return str.replace(/("|'|\x5c)/g, '').trim();
  }

  static split(header: string): string[] {
    return header.split('>,').map((value: string) => {
      return HeaderRegExpModel.removeQuotes(value).replace(/(\x3c|\x3e)/g, '').trim();
    });
  }

  static getListMail(header: string): IMessageDetailHeader[] {
    return HeaderRegExpModel.split(header).map((item: string) => {
      const result: any = {
        name: null,
        email: header
      };
      let m: string[];
      if ((m = /(^.+ )?(.+)/g.exec(item)) !== null) {
        result.name = m[1] ? m[1].trim() : null;
        result.email = m[2] ? m[2].toLowerCase() : result.email;
      }

      return result;
    }).filter((item) => item.email && item.email.length);
  }
}
