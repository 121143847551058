export class ConfigDefaults {
  /**
   * скривать цитируемое сообщения
   */
  _emailToggleQuote?: boolean;
  /**
   * @deprecated
   */
  focus?: boolean;
  /**
   * Only the minimum required events are initialized on page load and the rest of
   * them when the user clicks inside the editable area. This is very useful when using
   * multiple editors on the same page because it reduces the page load time.
   * Default:	false
   */
  initOnClick?: boolean;
  /**
   * The plugins that should be enabled in the current editor instance. By default, all plugins are enabled. Available plugins are:
   * Default:	null
   */
  pluginsEnabled?: string[];
  /**
   * The list of allowed tags.
   */
  htmlAllowedTags?: string[];
  /**
   * The list of tags that are removed together with their content.
   * Type:	Array
   * Default:	['script', 'style']
   */
  htmlRemoveTags?: string[];
  /**
   * The list of allowed attributes to be used for tags.
   */
  htmlAllowedAttrs?: string[];
  /**
   * The list of allowed CSS attributes to be used for tags.
   * Type:	Array
   * Default:	[]
   */
  htmlAllowedStyleProps?: string[];
  htmlAllowComments?: boolean;
  htmlUntouched?: boolean;
  fullPage?: boolean;
  htmlAllowedEmptyTags?: string[];
  htmlDoNotWrapTags?: string[];
  htmlSimpleAmpersand?: boolean;
  /**
   * A list of CSS properties to be ignored when useClasses option is disabled and the
   * CSS from external stylesheets is converted to inline style.
   * Default:	[]
   */
  htmlIgnoreCSSProperties?: string[];
  /**
   * Allow running scripts that are entered in Code View.
   * Type:	Boolean
   * Default:	true
   */
  htmlExecuteScripts?: boolean;
  /**
   * Set a height for the rich text editor's editing box.
   * Default:	null
   */
  height?: number;
  heightMax?: number;
  heightMin?: number;
  width?: number;
  /**
   * Select the language to be used in the rich text editor's interface.
   * The corresponding language file is required: /langs/es.js.
   * Default:	null
   */
  language?: string;
  /**
   * The placeholder used when the WYSIWYG editor body is empty.
   * Default:	'Type Something'
   */
  placeholderText?: string;
  /**
   * Set a custom class for the WYSIWYG editor editing box.
   * Default:	null
   */
  editorClass?: string;
  typingTimer?: number;
  iframe?: boolean;
  requestWithCORS?: boolean;
  requestWithCredentials?: boolean;
  requestHeaders?: {[key: string]: string};
  useClasses?: boolean;
  spellcheck?: boolean;
  iframeDefaultStyle?: string;
  iframeStyle?: string;
  iframeStyleFiles?: any[];
  direction?: string;
  zIndex?: number;
  tabIndex?: number;
  disableRightClick?: boolean;
  scrollableContainer?: string;
  keepFormatOnDelete?: boolean;
  theme?: string;
  /**
   * Set the default tag to be used when ENTER key is hit.
   * Possible values are
   * $.FroalaEditor.ENTER_P = 0
   * $.FroalaEditor.ENTER_DIV = 1
   * $.FroalaEditor..ENTER_BR = 2
   */
  enter?: 0 | 1 | 2;
  multiLine?: boolean;
  tabSpaces?: number;
  indentMargin?: number;
  /**
   * Removes text formatting when pasting content into the rich text editor, but keeps the content's structure.
   * Default:	false
   */
  pastePlain?: boolean;
  pasteDeniedTags?: string[];
  pasteDeniedAttrs?: string[];
  pasteAllowedStyleProps?: string[];
  pasteAllowLocalImages?: boolean;
  shortcutsEnabled?: string[];
  shortcutsHint?: boolean;
  iconsTemplate?: string;
  tooltips?: boolean;
  editInPopup?: boolean;
  toolbarBottom?: boolean;
  /**
   * The list of buttons that appear in the rich text editor's toolbar on large devices (≥ 1200px).
   * Note: | will insert a vertical separator line in the toolbar, and - a horizontal one.
   */
  toolbarButtons?: string[];
  toolbarButtonsXS?: string[];
  toolbarButtonsSM?: string[];
  toolbarButtonsMD?: string[];
  /**
   * A custom HTML selector placing the toolbar inside.
   */
  toolbarContainer?: string;
  toolbarInline?: boolean;
  toolbarSticky?: boolean;
  toolbarStickyOffset?: number;
  toolbarVisibleWithoutSelection?: boolean;
  charCounterMax?: number;
  /**
   * Enables or disables the display of the character counter.
   * Default:	true
   * Plugin:	char_counter.min.js
   */
  charCounterCount?: boolean;
  codeMirrorOptions?: ICodeMirrorOptions;
  codeBeautifierOptions?: ICodeBeautifierOptions;
  codeViewKeepActiveButtons?: string[];
  colorsText?: string[];
  colorsBackground?: string[];
  colorsStep?: number;
  /**
   * Show HEX input to choose custom color.
   * Default:	true
   * Plugin:	colors.min.js
   */
  colorsHEXInput?: boolean;
  colorsDefaultTab?: string;
  colorsButtons?: string[];
  /**
   * Specifies how the dragged elements should be placed in the new position.
   * When this option is disabled, the dragged elements are placed between block tags and not inside them.
   * Default:	true
   * Plugin:	draggable.min.js
   */
  dragInline?: boolean;
  emoticonsStep?: number;
  emoticonsSet?: IEmoticonsSet[];
  emoticonsButtons?: string[];
  /**
   * Use EmojiOne svg images instead of Unicode text.
   * Default:	true
   * Plugin:	emoticons.min.js
   */
  emoticonsUseImage?: boolean;
  entities?: string;
  /**
   * Enable or disable file upload.
   * Default:	true
   * Plugin:	file.min.js
   */
  fileUpload?: boolean;
  /**
   * The URL where the files uploaded by the user are saved.
   * Default:	'http://i.froala.com/upload'
   * Plugin:	file.min.js
   */
  fileUploadURL?: string;
  fileUploadParam?: string;
  fileUploadParams?: {[key: string]: string};
  fileUploadToS3?: boolean;
  fileUploadMethod?: string;
  fileMaxSize?: number;
  fileAllowedTypes?: string[];
  fileInsertButtons?: string[];
  fileUseSelectedText?: boolean;
  /**
   * Defines the fonts that appear under the Font Family button from the rich text editor's toolbar.
   */
  fontFamily?: {[key: string]: string};
  /**
   * The Font Family button from the WYSIWYG editor's toolbar is replaced with a
   * dropdown showing the actual font family name for the current text selection.
   * Default:	false
   * Plugin:	font_family.min.js
   */
  fontFamilySelection?: boolean;
  /**
   * The text to display when the font family is unkown or nothing is selected.
   * Default:	'Font Family'
   * Plugin:	font_family.min.js
   */
  fontFamilyDefaultSelection?: string;
  fontSize?: string[];
  /**
   * The Font Size button from the WYSIWYG editor's toolbar is replaced with a
   * dropdown showing the actual font size value for the current text selection.
   * Default:	false
   * Plugin:	font_size.min.js
   */
  fontSizeSelection?: boolean;
  fontSizeDefaultSelection?: string;
  fontSizeUnit?: string;
  formEditButtons?: string[];
  formStyles?: {[key: string]: string};
  formMultipleStyles?: boolean;
  formUpdateButtons?: string[];
  helpSets?: IHelpSet[];
  imageInsertButtons?: string[];
  imageEditButtons?: string[];
  imageAltButtons?: string[];
  imageSizeButtons?: string[];
  imageUpload?: boolean;
  /**
   * The URL where the images uploaded by the user are saved.
   * Default:	'http://i.froala.com/upload'
   * Plugin:	image.min.js
   */
  imageUploadURL?: string;
  imageCORSProxy?: string;
  imageUploadRemoteUrls?: boolean;
  imageUploadParam?: string;
  imageUploadParams?: {[key: string]: string};
  imageUploadToS3?: boolean;
  imageUploadMethod?: string;
  imageMaxSize?: number;
  imageAllowedTypes?: string[];
  imageResize?: boolean;
  imageResizeWithPercent?: boolean;
  imageRoundPercent?: boolean;
  /**
   * Sets the default width of the image when it is inserted in the rich text editor.
   * Setting it to 0 will not set any width.
   * Default:	300
   * Plugin:	image.min.js
   */
  imageDefaultWidth?: number;
  /**
   * Sets the default image alignment when it is inserted in the rich text editor.
   * Possible values are 'left', 'center' and 'right'.
   * Default:	'center'
   * Plugin:	image.min.js
   */
  imageDefaultAlign?: 'left' | 'center' | 'right';
  /**
   * Sets the default display for an image when is is inserted in the rich text.
   * Possible options are: 'inline' and 'block'.
   * Default:	'block'
   * Plugin:	image.min.js
   */
  imageDefaultDisplay?: 'inline' | 'block';
  imageSplitHTML?: boolean;
  imageStyles?: {[key: string]: string};
  imageMove?: boolean;
  imageMultipleStyles?: boolean;
  imageTextNear?: boolean;
  imagePaste?: boolean;
  imagePasteProcess?: boolean;
  imageMinWidth?: number;
  imageOutputSize?: boolean;
  imageDefaultMargin?: number;
  imageManagerLoadURL?: string;
  imageManagerLoadMethod?: string;
  imageManagerLoadParams?: {[key: string]: string};
  /**
   * The path to a gif image to be displayed while loading the images from the server in the image manager.
   * If no option is specified, "Loading.." text will appear.
   * Default:	''
   * Plugin:	image_manager.min.js
   */
  imageManagerPreloader?: string;
  imageManagerDeleteURL?: string;
  imageManagerDeleteMethod?: string;
  imageManagerDeleteParams?: {[key: string]: string};
  imageManagerPageSize?: number;
  imageManagerScrollOffset?: number;
  imageManagerToggleTags?: boolean;
  inlineStyles?: {[key: string]: string};
  lineBreakerTags?: string[];
  lineBreakerOffset?: number;
  lineBreakerHorizontalOffset?: number;
  linkEditButtons?: string[];
  linkInsertButtons?: string[];
  linkAttributes?: {[key: string]: string};
  linkAutoPrefix?: string;
  linkStyles?: {[key: string]: string};
  linkMultipleStyles?: boolean;
  linkConvertEmailAddress?: boolean;
  linkAlwaysBlank?: boolean;
  /**
   * Specifies if the rel="nofollow" attribute should be added on all links.
   * Default:	true
   * Plugin:	link.min.js
   */
  linkAlwaysNoFollow?: boolean;
  linkNoOpener?: boolean;
  linkNoReferrer?: boolean;
  linkList?: ILinkList[];
  linkText?: boolean;
  paragraphFormat?: {[key: string]: string};
  paragraphFormatSelection?: boolean;
  paragraphDefaultSelection?: string;
  paragraphStyles?: {[key: string]: string};
  paragraphMultipleStyles?: boolean;
  quickInsertButtons?: string[];
  quickInsertTags?: string[];
  saveInterval?: number;
  /**
   * The URL where the save request is being made. The editor will initialize a HTTP request to
   * the specified URL passing the editor's content in the body parameter of the HTTP request.
   * Default:	null
   * Plugin:	save.min.js
   */
  saveURL?: string;
  saveParams?: {[key: string]: string};
  saveParam?: string;
  saveMethod?: string;
  specialCharactersSets?: ISpecialCharactersSet[];
  tableInsertMaxSize?: number;
  tableEditButtons?: string[];
  tableInsertButtons?: string[];
  tableResizer?: boolean;
  tableDefaultWidth?: string;
  tableResizerOffset?: number;
  tableResizingLimit?: number;
  tableColorsButtons?: string[];
  tableColors?: string[];
  tableColorsStep?: number;
  tableCellStyles?: {[key: string]: string};
  tableStyles?: {[key: string]: string};
  tableCellMultipleStyles?: boolean;
  tableMultipleStyles?: boolean;
  tableInsertHelper?: boolean;
  tableInsertHelperOffset?: number;
  videoAllowedTypes?: string[];
  videoAllowedProviders?: string[];
  videoDefaultAlign?: string;
  videoDefaultDisplay?: string;
  videoDefaultWidth?: number;
  videoEditButtons?: string[];
  videoInsertButtons?: string[];
  videoMaxSize?: number;
  videoMove?: boolean;
  videoResize?: boolean;
  videoSizeButtons?: string[];
  videoSplitHTML?: boolean;
  videoTextNear?: boolean;
  videoUpload?: boolean;
  videoUploadMethod?: string;
  videoUploadParam?: string;
  videoUploadParams?: {[key: string]: string};
  videoUploadToS3?: boolean;
  /**
   * The URL where the videos uploaded by the user are saved.
   * Default:	'http://i.froala.com/upload'
   * Plugin:	video.min.js
   */
  videoUploadURL?: string;
  /**
   * Tags that are removed together with their content when pasting something into the rich text editor from Word.
   */
  wordDeniedTags?: string[];
  /**
   * Attributes that are removed when pasting something into the rich text editor from Word.
   */
  wordDeniedAttrs?: string[];
  wordAllowedStyleProps?: string[];
  wordPasteModal?: boolean;
  wordPasteKeepFormatting?: boolean;
}

interface ISpecialCharactersSet {
  title?: string;
  list?: IList[];
}

interface IList {
  char?: string;
  desc?: string;
}

interface ILinkList {
  text?: string;
  href?: string;
  target?: string;
  displayText?: string;
}

interface IHelpSet {
  title?: string;
  commands?: ICommand[];
}

interface ICommand {
  val?: string;
  desc?: string;
}

interface IEmoticonsSet {
  code?: string;
  desc?: string;
}

interface ICodeBeautifierOptions {
  end_with_newline?: boolean;
  indent_inner_html?: boolean;
  extra_liners?: string[];
  brace_style?: string;
  indent_char?: string;
  indent_size?: number;
  wrap_line_length?: number;
}

interface ICodeMirrorOptions {
  lineNumbers?: boolean;
  tabMode?: string;
  indentWithTabs?: boolean;
  lineWrapping?: boolean;
  mode?: string;
  tabSize?: number;
}
