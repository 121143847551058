import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
/**
 * Всплывающее уведомление
 */
export class PushNotificationService {
    constructor(router) {
        this.router = router;
        this.permission = this.isSupported() ? Notification.permission : 'denied';
    }
    /**
     * Запрашивает разрешение у пользователя показать уведомления.
     */
    requestPermission() {
        if ('Notification' in window) {
            Notification.requestPermission((status) => this.permission = status);
        }
    }
    isSupported() {
        return 'Notification' in window;
    }
    /**
     * Выводим уведомления в браузере
     * @param title
     * @param options
     * @param isFocus
     * @param router
     */
    push(title, options, isFocus = true, router) {
        const selfWin = window;
        if (this.permission === 'granted') {
            this.create(title, options).subscribe((res) => {
                // console.log('----', res);
                if (res.event && res.event.type === 'show') {
                }
                else if (res.event && res.event.type === 'click') {
                    // Закрываем уведомления при клике по нему
                    res.notification.close();
                    if (isFocus && !document.hasFocus() && 'focus' in window) {
                        window.focus();
                        if (router) {
                            return this.router.navigateByUrl(router);
                        }
                    }
                }
            }, err => {
                console.log(err);
            });
        }
    }
    create(title, options) {
        return new Observable((obs) => {
            if (!('Notification' in window)) {
                obs.error('Notifications are not available in this environment');
                obs.complete();
            }
            if (this.permission !== 'granted') {
                obs.error(`The user hasn't granted you permission to send push notifications`);
                obs.complete();
            }
            const n = new Notification(title, options);
            n.onshow = (e) => obs.next({ notification: n, event: e });
            n.onclick = (e) => obs.next({ notification: n, event: e });
            n.onerror = (e) => obs.error({ notification: n, event: e });
            n.onclose = () => obs.complete();
        });
    }
}
PushNotificationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PushNotificationService_Factory() { return new PushNotificationService(i0.ɵɵinject(i1.Router)); }, token: PushNotificationService, providedIn: "root" });
/**
 * @example http://findicons.com/icon/499071/chat_emoticon_cross
 */
export const PushNotificationIcon = {
    mail: 'assets/icons/mail.png',
};
