import { BehaviorSubject } from 'rxjs';
import { DbStorageService } from './db-storage.service';
import * as i0 from "@angular/core";
import * as i1 from "./db-storage.service";
/**
 * https://www.google.com/contacts/u/1/?cplus=0#contacts/group/27/%D0%9A%D0%B0%D1%82%D0%B0%D0%BB%D0%BE%D0%B3
 * Группы
 * https://developers.google.com/people/api/rest/v1/contactGroups/list
 * Doc api
 * https://developers.google.com/contacts/v3/reference#contacts-query-parameters-reference
 * https://developers.google.com/contacts/v3/#working_with_contact_groups
 *
 * ##
 * https://developers.google.com/gdata/docs/directory
 *
 * DD
 * http://www.pirules.org/extensions/gcontactsync/jsdoc/symbols/src/_home_josh_gcontactsync_src3_content_gdata.js.html
 */
export class ContactsService {
    constructor(dbStorage) {
        this.dbStorage = dbStorage;
        /**
         * Интервал между синхронизацией контактов с google
         * время в секундах
         */
        this.timeUpdate = 3600; // 1hour
        /**
         * Список контактов
         */
        this.contacts = {};
        this.list = new BehaviorSubject({});
    }
    getGA() {
        return {
            token: this.accessToken,
            email: this.authEmail
        };
    }
    load(items) {
        if (items && Array.isArray(items)) {
            items.forEach((item) => {
                this.contacts[item.email] = item.name;
                this.dbStorage.getTable('contacts').subscribe((db) => {
                    db.get(item.email).subscribe((row) => {
                        /**
                         * если email нет или имя не совпадает
                         * тогда обновляем или создаем контакт
                         */
                        if (!row || row && row.value !== item.name) {
                            db.put({
                                id: item.email,
                                value: item.name
                            }).subscribe();
                        }
                    });
                });
            });
            this.list.next(this.contacts);
            /**
             * Обновляем время последней загрузки
             */
            this.dbStorage.getTable('settings').subscribe((db) => {
                db.put({
                    id: 'lastUpdateContacts',
                    value: Date.now()
                }).subscribe();
            });
        }
    }
    init(accessToken, authEmail) {
        this.accessToken = accessToken || null;
        this.authEmail = authEmail || null;
    }
}
ContactsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ContactsService_Factory() { return new ContactsService(i0.ɵɵinject(i1.DbStorageService)); }, token: ContactsService, providedIn: "root" });
