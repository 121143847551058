import {Injectable, NgZone} from '@angular/core';
import {Router} from '@angular/router';
import {BehaviorSubject, timer} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';

import {AppConfig} from '../../app.config';
import {Auth, IAuth, ISettingGmail} from '../model';
import {SettingService} from './setting.service';
import {WsService} from './ws.service';
import {ContactsService} from './contacts.service';

@Injectable({
  providedIn: 'root'
})
export class GApiService {

  public isSignedIn: BehaviorSubject<boolean> = new BehaviorSubject(null);

  public auth: BehaviorSubject<IAuth> = new BehaviorSubject(null);
  public authResponse: BehaviorSubject<IAuthResponse> = new BehaviorSubject(null);
  /**
   * обновить токен доступа
   */
  public isRefreshToken: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private isInit = false;
  /**
   * Интервал времени по которому проверяется жизнь сессии
   */
  private trackerSession = timer(new Date(), 60000);

  constructor(
    private router: Router,
    private setting: SettingService,
    private contacts: ContactsService,
    private ws: WsService,
    private ngZone: NgZone
  ) {
    /**
     * После авторизации на сервере поверяем присутствие (sendAs) у пользователя.
     * Если ево нет то загружаем с google и сохраняем в DB
     */
    this.ws.auth.pipe(distinctUntilChanged())
      .subscribe((auth: any) => {
        // @TODO старый параметр [_id]
        // console.log(auth)
        if (auth && auth._id && auth.id) {

          if (auth.sendAs && auth.sendAs.length > 0) {
            this.setting.paramGMail.next(auth.sendAs);
          } else {
            this.getListSettingSendAs(auth.id).then((sendAs: any) => {

              this.setting.paramGMail.next(sendAs);

              if (sendAs && sendAs.length) {
                this.ws.toSend({
                  cf: 'user.update',
                  data: {
                    id: auth._id,
                    data: {
                      sendAs: sendAs || []
                    }
                  }
                }, true)
                  .then((_user: any) => {})
                  .catch((err: any) => {
                    console.log(err);
                  });
              }
            });
          }
        }
      });

    this.auth.subscribe((auth: IAuth) => {
      if (auth) {

        const authResponse = <IAuthResponse>gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse();
        this.authResponse.next(authResponse);

        if (authResponse) {

          this.sessionMonitoring();

          if (authResponse && authResponse.access_token) {
            this.contacts.init(authResponse.access_token, auth.email);
          }
        }
      }
    });

    this.isRefreshToken.pipe(distinctUntilChanged()).subscribe((isRefresh: boolean) => {
      if (isRefresh) {
        this.refreshToken();
      }
    });
  }

  /**
   * Продливаем token доступа к google
   */
  private refreshToken() {
    console.log('refreshToken');
    gapi.auth2.getAuthInstance().currentUser.get().reloadAuthResponse()
      .then((AuthResponse: IAuthResponse) => {
        console.log(AuthResponse);
        this.isSignedIn.next(true);
        this.authResponse.next(AuthResponse);
        this.isRefreshToken.next(false);
      })
      .catch((e) => {
        console.log(e);
        this.isSignedIn.next(false);
        this.authResponse.next(null);
        this.isRefreshToken.next(false);
        return this.signOut();
      });
  }
  /**
   * Монитор сессию
   * если до окончания сесии остается меньше 10% то продливаем ее
   */
  private sessionMonitoring() {

    this.trackerSession.subscribe((step: number) => {

      const authResponse = this.authResponse.getValue();

      if (authResponse) {

        if (authResponse.expires_at) {

          const theEnd = Math.ceil((authResponse.expires_at - Date.now()) / 1000);
          const unitEnd = Math.ceil(authResponse.expires_in * 0.10);
          // console.log(`${theEnd} < ${unitEnd}`);
          if (theEnd < unitEnd) {
            this.isRefreshToken.next(true);
          }
        }
      }
    });
  }

  init(): Promise<boolean> {

    return new Promise((resolve) => {
      if (this.isInit) {
        resolve(true);
      } else {
        const googleAuth = gapi.auth2.getAuthInstance();
        this.isSignedIn.next(googleAuth.isSignedIn.get());

        const currentUser = googleAuth.currentUser.get();

        if (currentUser) {
          this.isScopes(currentUser.getAuthResponse());
        }

        this.initCurrentUser(currentUser.getBasicProfile());
        /**
         * Мониторим авторизацию пользователя в google
         */
        googleAuth.isSignedIn.listen((isSignedIn: boolean) => {

          if (isSignedIn) {
            this.initCurrentUser(googleAuth.currentUser.get().getBasicProfile());

            this.isScopes(googleAuth.currentUser.get().getAuthResponse());
          } else {
            this.initCurrentUser(null);
            /**
             * Если пользователь вышел из google
             * то обновляем token
             */
            this.isRefreshToken.next(true);
          }
        });

        this.isInit = true;
        resolve(true);
      }
    });
  }

  /**
   * Проверяем есть ли разрешения для scope
   * @param authResponse
   */
  private isScopes(authResponse: any): void {

    if (authResponse.scope && authResponse.scope.indexOf('https://www.googleapis.com/auth/contacts') === -1) {
      const googleAuth = gapi.auth2.getAuthInstance();

      googleAuth.disconnect();
      googleAuth.signOut();

      return window.location.reload();
    }
  }

  /**
   * Войти в систему
   * https://developers.google.com/api-client-library/javascript/reference/referencedocs#gapiauth2clientconfig
   */
  public signIn(): Promise<boolean> {

    return new Promise((resolve) => {
      gapi.auth2.getAuthInstance().signIn({
        fetch_basic_profile: true,
        prompt: 'select_account', // consent | select_account | none
        scope: AppConfig.scope,
      }).then((auth) => {

        this.isSignedIn.next(true);
        this.initCurrentUser(auth.getBasicProfile());
        this.isScopes(auth.getAuthResponse());
        resolve(true);

        return this.ngZone.run(() => {
          return this.router.navigateByUrl('/');
        });

      }, (err: any) => {
        console.log(err);
        resolve(false);
      });
    });
  }

  /**
   * Выход из системы
   */
  async signOut() {

    await this.isSignedIn.next(false);
    await this.auth.next(null);
    await gapi.auth2.getAuthInstance().signOut();

    await this.ws.toSend({
      cf: 'user.signOut',
      data: {}
    }, true).then((status: boolean) => {
      this.ws.auth.next(null);
    }).catch((err: any) => {
      console.log(err);
    });

    return this.ngZone.run(() => {
      (window as any).location = '#/login';
      return this.router.navigateByUrl('/login');
    });
  }

  private initCurrentUser(profile?: any) {

    if (profile) {

      const auth = new Auth(
        profile.getId(),
        profile.getEmail(),
        profile.getFamilyName(),
        profile.getGivenName(),
        profile.getName(),
        profile.getImageUrl()
      );

      if (auth.id && auth.id.length) {

        this.auth.next(auth);
        this.ws.init(auth);

        this.getListSettingSendAs(auth.id).then((response) => {
          this.setting.paramGMail.next(response);
        });
      }
    } else {
      this.auth.next(null);
      this.setting.paramGMail.next([]);
    }
  }

  /**
   * Перечисляет псевдонимы sendAs для указанной учетной записи.
   * Результат включает основной адрес отправки, связанный с учетной записью,
   * а также любые пользовательские псевдонимы "from".
   *
   * @link https://developers.google.com/gmail/api/v1/reference/users/settings/sendAs/list
   */
  private getListSettingSendAs(userId: string): Promise<ISettingGmail[]> {

    return new Promise((resolve) => {
      gapi.client.gmail.users.settings.sendAs.list({
        userId: userId
      }).then((response: any) => {
        resolve(response.result.sendAs || []);
      }, (err) => {
        console.log(err);
        resolve([]);
      });
    });
  }
}

/**
 * https://developers.google.com/identity/sign-in/web/reference
 */
export interface IAuthResponse {
  token_type: string;
  // токен доступа.
  access_token: string;
  scope: string;
  login_hint: string;
  // Количество секунд до истечения срока действия токена доступа.
  expires_in: number;
  // Идентификатор ID предоставлен.
  id_token: string;
  session_state: IAuthResponseSessionState;
  // Временная метка, в которой пользователь сначала предоставил запрашиваемые области.
  first_issued_at: number;
  // Временная метка, по которой истекает срок действия маркера доступа.
  expires_at: number;
  idpId: string;
}

interface IAuthResponseSessionState {
  extraQueryParams: IAuthResponseSessionStateExtraQueryParams;
}

interface IAuthResponseSessionStateExtraQueryParams {
  authuser: string;
}
