<div class="simple-notification"
     [@enterLeave]="item.state"
     (click)="onClick($event)"
     [class]="theClass"
     [ngClass]="{
            'alert': item.type === 'alert',
            'error': item.type === 'error',
            'warn': item.type === 'warn',
            'success': item.type === 'success',
            'info': item.type === 'info',
            'bare': item.type === 'bare',
            'rtl-mode': rtl,
            'has-icon': item.icon !== 'bare'
        }"
     (mouseenter)="onEnter()"
     (mouseleave)="onLeave()">

    <div *ngIf="!item.html">

        <div class="sn-title" *ngIf="titleIsTemplate else regularTitle">
            <ng-container *ngTemplateOutlet="title"></ng-container>
        </div>

        <ng-template #regularTitle>
            <div class="sn-title" [innerHTML]="title"></div>
        </ng-template>

        <div class="sn-content" *ngIf="contentIsTemplate else regularContent">
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </div>

        <ng-template #regularContent>
            <div class="sn-content" [innerHTML]="content"></div>
        </ng-template>

        <div class="icon" *ngIf="item.icon !== 'bare'" [innerHTML]="safeSvg"></div>
    </div>
    <div *ngIf="item.html">
        <div class="sn-html" *ngIf="htmlIsTemplate else regularHtml">
            <ng-container *ngTemplateOutlet="item.html"></ng-container>
        </div>

        <ng-template #regularHtml>
            <div class="sn-content" [innerHTML]="item.html"></div>
        </ng-template>

        <div class="icon" [class.icon-hover]="clickIconToClose" *ngIf="item.icon" [innerHTML]="safeSvg" (click)="onClickIcon($event)"></div>
    </div>

    <div class="sn-progress-loader" *ngIf="showProgressBar">
        <span [ngStyle]="{'width': progressWidth + '%'}"></span>
    </div>

</div>