import * as tslib_1 from "tslib";
import { Subject } from 'rxjs';
import { MessageCodingModel } from './message-coding.model';
import { crc32 } from '../fn';
/**
 * Обработка вложений письма
 */
export class AttachmentModel {
    constructor(base64url, filename, contentType, sliceSize) {
        /**
         * Типы вложений
         * https://cloud.google.com/appengine/docs/standard/python/mail/mail-with-headers-attachments
         */
        this.types = {
            docx: 'application/msword',
            doc: 'application/msword',
            pdf: 'application/pdf',
            rss: 'application/rss+xml',
            kml: 'application/vnd.google-earth.kml+xml',
            kmz: 'application/vnd.google-earth.kmz\tkmz',
            xlsx: 'application/vnd.ms-excel',
            xls: 'application/vnd.ms-excel',
            pptx: 'application/vnd.ms-powerpoint',
            ppt: 'application/vnd.ms-powerpoint',
            pps: 'application/vnd.ms-powerpoint',
            odp: 'application/vnd.oasis.opendocument.presentation',
            ods: 'application/vnd.oasis.opendocument.spreadsheet',
            odt: 'application/vnd.oasis.opendocument.text',
            sxc: 'application/vnd.sun.xml.calc',
            sxw: 'application/vnd.sun.xml.writer',
            gzip: 'application/x-gzip',
            zip: 'application/zip',
            snd: 'audio/basic',
            au: 'audio/basic',
            flac: 'audio/flac',
            rmi: 'audio/mid',
            mid: 'audio/mid',
            m4a: 'audio/mp4',
            mp3: 'audio/mpeg',
            ogg: 'audio/ogg',
            oga: 'audio/ogg',
            aiff: 'audio/x-aiff',
            aifc: 'audio/x-aiff',
            aif: 'audio/x-aiff',
            wav: 'audio/x-wav',
            gif: 'image/gif',
            jpe: 'image/jpeg',
            jpg: 'image/jpeg',
            jpeg: 'image/jpeg',
            png: 'image/png',
            tif: 'image/tiff',
            tiff: 'image/tiff',
            wbmp: 'image/vnd.wap.wbmp',
            bmp: 'image/x-ms-bmp',
            ics: 'text/calendar',
            csv: 'text/comma-separated-values',
            css: 'text/css',
            html: 'text/html',
            htm: 'text/html',
            txt: 'text/plain',
            text: 'text/plain',
            pot: 'text/plain',
            patch: 'text/plain',
            log: 'text/plain',
            diff: 'text/plain',
            c: 'text/plain',
            asc: 'text/plain',
            vcf: 'text/x-vcard',
            mp4: 'video/mp4',
            mpe: 'video/mpeg',
            mpg: 'video/mpeg',
            mpeg: 'video/mpeg',
            ogv: 'video/ogg',
            mov: 'video/quicktime',
            qt: 'video/quicktime',
            avi: 'video/x-msvideo'
        };
        this.contentType = contentType || '';
        this.filename = filename;
        this.sliceSize = sliceSize || 512;
        this.normalizeBase64(base64url);
    }
    /**
     * Ищем img(base64) и заменяем их на img(cid:filename)
     * саму картинку прикрепляем в attach
     */
    static cidAdaptationDOM(DOMEmail) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const attachments = {};
            let key;
            try {
                for (key in DOMEmail.dom.images) {
                    if (DOMEmail.dom.images[key].src && DOMEmail.dom.images[key].src.indexOf('data:image/') === 0) {
                        const attach = yield new AttachmentModel(DOMEmail.dom.images[key].src);
                        const res = attach.toAttachment(false, +key);
                        // const filename = `${key}_${res.name}`;
                        // res.name = filename;
                        DOMEmail.dom.images[key].src = `cid:${res.name}`;
                        attachments[res.name] = res;
                    }
                }
                return attachments;
            }
            catch (e) {
                return e;
            }
        });
    }
    /**
     * Ищем img(base64) и заменяем их на img(cid:filename)
     * саму картинку прикрепляем в attach
     */
    static cidAdaptation(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const regex = AttachmentModel.searchBase64ImageReGex;
            let match;
            const attachments = {};
            let result = data;
            while ((match = regex.exec(data)) !== null) {
                if (match.index === regex.lastIndex) {
                    regex.lastIndex++;
                }
                const attach = yield new AttachmentModel(match[1]);
                const res = attach.toAttachment();
                // attachments[attach.filename] = await attach.toAttachment();
                attachments[res.name] = res;
                result = yield attach.replaceAttachmentToCid(result, match[1], attach.filename);
            }
            return {
                textHtml: result,
                attachments: attachments
            };
        });
    }
    normalizeBase64(base64) {
        this.base64 = base64.replace(/-/g, '+').replace(/_/g, '/');
        return this;
    }
    /**
     * Скачать вложения
     */
    download() {
        if (this.filename) {
            const elem = window.document.createElement('a');
            elem.href = window.URL.createObjectURL(this.base64toBlob());
            elem.download = this.filename;
            document.body.appendChild(elem);
            elem.click();
            document.body.removeChild(elem);
        }
    }
    /**
     * data:{mime/type string};base64,{base64 string}
     */
    getDataImageBase64() {
        const mimeType = this.getMimeTypes();
        return mimeType ? 'data:' + mimeType + ';base64,' + this.base64 : '';
    }
    /**
     * https://developer.mozilla.org/ru/docs/Web/API/URL/createObjectURL
     */
    getBlobURL() {
        return window.URL.createObjectURL(this.base64toBlob());
    }
    /**
     * https://developer.mozilla.org/ru/docs/Web/API/Blob/Blob
     */
    getBlob() {
        return this.base64toBlob();
    }
    getMimeTypes() {
        const extension = this.getExtension();
        return this.types[extension] ? this.types[extension] : null;
    }
    /**
     * Получить расширение
     */
    getExtension() {
        const extension = this.filename.replace(/^.*?\.([a-zA-Z0-9]+)$/, '$1');
        return extension ? extension.toLowerCase().trim() : '';
    }
    /**
     * Преобразуем image base64 в attachment для отправки
     * @param {boolean} cid
     * @param {number} num
     * @returns {{
     *      mime: string,
      *     name: string;
      *     size: number;
      *     type: string;
      *     cid: boolean
      * }}
     */
    toAttachment(cid = false, num = 0) {
        const regex = /data:image\/(.+);base64,([a-zA-Z0-9+\/=]+)/m;
        let m;
        if ((m = regex.exec(this.base64)) !== null) {
            this.base64 = MessageCodingModel.enReplace(m[2]);
            this.base64 = m[2];
            if (!this.filename) {
                this.filename = num + crc32(m[2]) + '.' + m[1];
            }
            else {
                this.filename = num + this.filename;
            }
            this.contentType = 'image/' + m[1];
        }
        return {
            mime: this.getMimeMessage(),
            name: this.filename,
            size: Buffer.byteLength(this.base64),
            type: this.contentType,
            cid: cid
        };
    }
    /**
     * Меняем src=base64 на src=cid:filename
     */
    replaceAttachmentToCid(htmlMsg, match, filename) {
        let startStr, endStr, searchValue;
        if (htmlMsg.length > 100) {
            startStr = match.substr(0, 50)
                .replace(/\+/g, '\\+')
                .replace(/\//g, '\\/');
            endStr = match.substr(-50)
                .replace(/\+/g, '\\+')
                .replace(/\//g, '\\/');
            return htmlMsg.replace(new RegExp(startStr + '.+' + endStr, 'gi'), 'cid:' + filename);
        }
        else {
            searchValue = match.replace(/\+/g, '\\+').replace(/\//g, '\\/');
            return htmlMsg.replace(new RegExp(searchValue, 'gi'), 'cid:' + filename);
        }
    }
    /**
     * Формируем для отправки для отправки вложения
     */
    getMimeMessage() {
        const nl = '\n';
        if (!this.contentType || !this.filename || !this.base64) {
            return null;
        }
        return 'Content-Type: "' + this.contentType + '"; name="' + this.filename + '"' + nl +
            'Content-Disposition: attachment; filename="' + this.filename + '"' + nl +
            'Content-Transfer-Encoding: base64' + nl +
            'Content-ID: <' + this.filename + '>' + nl +
            '' + nl + nl + this.base64;
    }
    base64toBlob() {
        const byteCharacters = window.atob(this.base64);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += this.sliceSize) {
            const slice = byteCharacters.slice(offset, offset + this.sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        return new Blob(byteArrays, {
            type: this.contentType
        });
    }
}
AttachmentModel.searchBase64ImageReGex = /src="(data:image\/([a-zA-Z]+);base64,([a-zA-Z0-9+\/=]+))"/g;
/**
 * Загружаем файл с ПК
 */
export class LoadingAttachment {
    constructor(file) {
        this.result = new Subject();
        this.name = file.name;
        this.size = file.size;
        this.type = file.type;
        this.reader = new FileReader();
        this.reader.onload = (e) => {
            this.onLoad(e.target);
        };
        this.reader.readAsDataURL(file);
    }
    /**
     * Файл загружен успешно
     */
    onLoad(target) {
        this.type = (this.type && this.type.length) ? this.type : 'application/octet-stream';
        this.result.next({
            type: this.type,
            name: this.name,
            size: this.size,
            mime: this.getMime(this.getBase64(target.result), this.type)
        });
    }
    /**
     * Достаем содержымое файла в Base64
     */
    getBase64(dataBase64) {
        const regex = /^data:(.*);base64,(.+)/;
        let m;
        if ((m = regex.exec(dataBase64)) !== null) {
            return m[2];
        }
        else {
            return '';
        }
    }
    getMime(base64, type = '') {
        const nl = '\n';
        return 'Content-Type: "' + type + '"; name="' + this.name + '"' + nl +
            'Content-Disposition: attachment; filename="' + this.name + '"' + nl +
            'Content-Transfer-Encoding: base64' + nl +
            '' + nl + nl + base64;
    }
}
/**
 * Клас для загрузки файла с сервера
 * и формирование атача для отправки в письме
 */
export class UrlToBase64Attachment {
    constructor(url, filename) {
        this.url = url;
        this.filename = filename;
    }
    static getAttachment(url, filename) {
        return new UrlToBase64Attachment(url, filename).attachment();
    }
    attachment() {
        return new Promise((resolve, reject) => {
            this.download()
                .then((base64) => {
                resolve(this.getResult(base64));
            })
                .catch((err) => reject(err));
        });
    }
    /**
     * Качаем файл с сервера
     */
    download() {
        return new Promise((resolve, reject) => {
            const request = new XMLHttpRequest();
            request.open('GET', this.url, true);
            request.withCredentials = true;
            request.responseType = 'blob';
            request.onload = () => {
                const reader = new FileReader();
                reader.readAsDataURL(request.response);
                reader.onload = (e) => {
                    this.contentType = request.response.type;
                    this.size = request.response.size;
                    const list = e.target.result.split(';base64,');
                    resolve(list[1]);
                };
                reader.onerror = () => {
                    reject(new Error('Error load file.'));
                };
            };
            request.send();
        });
    }
    /**
     * Файл загружен успешно
     */
    getResult(base64) {
        return {
            type: this.contentType,
            name: this.filename,
            size: this.size,
            mime: this.getMime(base64)
        };
    }
    getMime(base64) {
        const nl = '\n';
        return 'Content-Type: "' + this.contentType + '"; name="' + this.filename + '"' + nl +
            'Content-Disposition: attachment; filename="' + this.filename + '"' + nl +
            'Content-Transfer-Encoding: base64' + nl +
            '' + nl + nl + base64;
    }
}
