import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'page-not-found', //tslint:disable-line
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  constructor() {}

  ngOnInit() {}
}
