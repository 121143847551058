import {Component, NgZone, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {GApiService} from '../../core/service';

@Component({
  selector: 'app-page-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  preserveWhitespaces: false
})
export class LoginComponent implements OnInit {

  idpiframeInitializationFailed = false;
  isChrome = false;

  constructor(
    private zone: NgZone,
    private router: Router,
    private gApi: GApiService
  ) {

    this.idpiframeInitializationFailed = 'idpiframeInitializationFailed' in window;
    this.isChrome = 'chrome' in window;
  }

  ngOnInit() {
    this.gApi.isSignedIn.subscribe((isAuth) => {

      if (isAuth) {
        return this.router.navigateByUrl('');
      }
    });
  }

  signIn() {
    this.gApi.signIn().then((signIn: boolean) => {
      return window.location.reload();
    }, (err: any) => {
      console.log('err', err);
    });
  }
}
