<svg width="100%" height="5" focusable="false" class="progress-bar-background progress-bar-element">
  <defs>
    <pattern [id]="progressbarId" x="5" y="0" width="10" height="5" patternUnits="userSpaceOnUse">
      <circle cx="2.5" cy="2.5" r="2.5"/>
    </pattern>
  </defs>
  <rect [attr.fill]="'url(#' + progressbarId + ')'" width="100%" height="100%"/>
</svg>
<div class="progress-bar-buffer progress-bar-element" [ngStyle]="_bufferTransform()"></div>
<div class="progress-bar-primary progress-bar-fill progress-bar-element" [ngStyle]="_primaryTransform()"></div>
<div class="progress-bar-secondary progress-bar-fill progress-bar-element"></div>
