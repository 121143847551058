import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {ContactsService} from './contacts.service';

/**
 * Подсказки Emails
 */
@Injectable({
  providedIn: 'root'
})
export class EmailTipService {

  items: any[] = [];
  list: any = {};
  contactsList: {[email: string]: string} = {};

  constructor(
    public contacts: ContactsService
  ) {

    // tslint:disable-next-line
    this.contacts.list.subscribe((contacts: {[email: string]: string}) => {

      this.contactsList = contacts;
      let email;
      for (email in contacts) {
        if (contacts[email].length) {
          this.list[email] = `${contacts[email]} <${email}>`;
        } else {
          this.list[email] = email;
        }
      }
    });
  }

  has(email: string) {
    return this.items.indexOf(email) > -1;
  }

  set(items: string[]) {
    this.items = this.items.concat(items);
    items.forEach((email: string) => {
      if (!this.list[email]) {
        this.list[email] = email;
      }
    });
  }

  /**
   * Поиск вводимого email в контактах оператора
   * @param text вводимый текст в форме
   */
  autocompleteEmailItems = (text: string) => {
    return of(
      Object.values(this.list).filter((v: string) => v.indexOf(text) > -1).slice(0, 5)
    );
  }

  /**
   * Автодополнения форм с контактов
   */
  searchAutoComplete(text: string): string[] {
    if (!text.length) {
      return [];
    }

    const result = new Set();
    // tslint:disable-next-line
    for (let email in this.contactsList) {

      if (this.contactsList[email].indexOf(text) > -1) {
        result.add(this.contactsList[email]);
        // result.add(`${this.contactsList[email]} <${email}>`);
      }
      if (email.indexOf(text) > -1) {
        result.add(email);
      }
    }

    // @ts-ignore
    return Array.from(result.values()).sort().slice(0, 5);
  }
}
