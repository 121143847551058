import {Component, HostBinding, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {CheckForUpdateService, WsService} from './core/service';
import {NavigationStart, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {filter} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  preserveWhitespaces: false
})
export class AppComponent implements OnInit {

  @HostBinding('class') get appClass() {
    let appClass = '';
    switch (window.location.hostname) {
      case 'local-gmail.binotel.space':
        appClass = 'app local';
        break;
      case 'localhost':
        appClass = 'app local';
        break;
      case 'gmail.binotel.space':
        appClass = 'app dev';
        break;
      case 'mm.binotel.com':
        appClass = 'app prod';
        break;
    }

    return appClass;
  }
  showErrorConnect = true;
  private language = 'ru';
  navStart: any;

  constructor(
    private http: HttpClient,
    private ws: WsService,
    private checkForUpdateService: CheckForUpdateService,
    private translate: TranslateService,
    private router: Router
  ) {
    // Create a new Observable the publishes only the NavigationStart event
    this.navStart = router.events.pipe(
      filter(evt => evt instanceof NavigationStart)
    ) as Observable<NavigationStart>;
  }

  ngOnInit(): void {
    /**
     * этот язык будет использоваться в качестве резервной копии,
     * если перевод не найден на текущем языке
     */
    this.translate.setDefaultLang('ru');
    // локализация указана пользователем
    this.translate.use('ru');

    this.ws.auth.subscribe((auth) => {
      if (auth) {
        if (this.language !== auth.language) {
          this.translate.use(auth.language);
          this.language = auth.language;
        }

        this.setAttrHtmlLang();
        this.setLanguageEditor();
      }
    });

    this.ws.state.subscribe((state) => {
      this.showErrorConnect = !(state === 'error' || state === 'close');
      // if (state === 'error' || state === 'close') {
      //   this.showErrorConnect = false;
      // } else {
      //   this.showErrorConnect = true;
      // }
    });
  }

  /**
   * Устанавливаем LANG для редактора
   */
  private setLanguageEditor() {

    let leng = 'en';
    if (['en', 'ru', 'ua'].indexOf(this.language.toLowerCase()) !== -1) {
      leng = this.language.toLowerCase();
    }

    this.http.get(`/assets/i18n/fe/${leng}.json`).subscribe((translate) => {
      try {
        (<any>$).FE.LANGUAGE[leng] = translate;
        (<any>$).FE.DEFAULTS.language = leng;
        (<any>$).FroalaEditor.LANGUAGE[leng] = translate;
        (<any>$).FroalaEditor.DEFAULTS.language = leng;
      } catch (e) {
        console.log('Error in set FE lang.', e);
      }
    });
  }

  /**
   * https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/lang
   * https://www.w3schools.com/jsref/prop_html_lang.asp
   * https://www.w3schools.com/tags/ref_language_codes.asp
   */
  private setAttrHtmlLang(): void {
    try {
      switch (this.language) {
        case 'ua':
          document.documentElement.lang = 'uk';
          break;
        case 'ru':
          document.documentElement.lang = 'ru';
          break;
        case 'en':
          document.documentElement.lang = 'en';
          break;
      }
    } catch (e) {
      console.log(e);
    }
  }
}
