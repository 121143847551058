import {ModuleWithProviders, NgModule} from '@angular/core';

import {FrEditorDirective} from './fr-editor.directive';
import {ConfigDefaults} from './config-defaults';
import './plugins/extend-toolbar-buttons';

@NgModule({
  imports: [],
  declarations: [
    FrEditorDirective
  ],
  exports: [
    FrEditorDirective
  ]
})
export class FrEditorModule {

  static withConfig(options?: ConfigDefaults): ModuleWithProviders {
    return {
      ngModule: FrEditorModule,
      providers: [
        ConfigDefaults,
        {
          provide: ConfigDefaults,
          useValue: options || {}
        }
      ]
    };
  }
}
