import { SettingService } from './setting.service';
import * as i0 from "@angular/core";
import * as i1 from "./setting.service";
export class PresentationLetterService {
    constructor(setting) {
        this.setting = setting;
        this.active = false;
        this.initPlugin();
    }
    setTemplate(template) {
        this.template = template || '';
    }
    initPlugin() {
        if (jQuery.FroalaEditor) {
            const _self = this;
            if (!jQuery.FroalaEditor.PLUGINS['presentationLetterTemplate']) {
                jQuery.FroalaEditor.PLUGINS.presentationLetterTemplate = function (editor) {
                    return {
                        _init: function () {
                            _self.active = false;
                        },
                        toggle: function (commandName) {
                            if (commandName !== 'presentationLetterTemplate') {
                                return 1;
                            }
                            if (!_self.active) {
                                const template = _self.setting.varTemplate(_self.template || '');
                                editor.html.set(template);
                                // editor.$el.css({
                                //   'background-color': '#e8e7e9',
                                //   'padding': '20px 0'
                                // });
                            }
                            else {
                                editor.html.set('');
                            }
                            if (editor.opts.iframe) {
                                setTimeout(() => {
                                    editor.size.syncIframe();
                                }, 100);
                            }
                            editor.events.focus();
                            _self.active = !_self.active;
                        },
                        apply: function (e) {
                            console.log('--p:apply', [e, this]);
                        },
                        refreshOnShow: function (e, e2) {
                            console.log('--p:refreshOnShow', [e, e2, this]);
                        },
                        refresh: function (e) {
                            console.log('--p:refresh', [e, this]);
                        }
                    };
                };
                jQuery.FroalaEditor.RegisterCommand('presentationLetterTemplate', {
                    title: 'Presentation letter',
                    // Specify the icon for the button.
                    // If this option is not specified, the button name will be used.
                    icon: '<i class="material-icons">redeem</i>',
                    // Save the button action into undo stack.
                    undo: true,
                    // Focus inside the editor before the callback.
                    // focus: true,
                    // Show the button on mobile or not.
                    // showOnMobile: false,
                    // Refresh the buttons state after the callback.
                    toggle: true,
                    refreshAfterCallback: true,
                    format: function (e) {
                        console.log('format: ', e, this);
                    },
                    refresh: function (button) {
                        if (_self.active && !button.hasClass('fr-active')) {
                            button.toggleClass('fr-active');
                        }
                        else if (button.hasClass('fr-active')) {
                            button.toggleClass('fr-active');
                        }
                    },
                    html: function (e) {
                        console.log('html: ', e, this);
                    },
                    callback: function (commandName) {
                        this.presentationLetterTemplate.toggle(commandName);
                    },
                    refreshOnShow: function (e, t) {
                        this.presentationLetterTemplate.refreshOnShow(e, t);
                    },
                    plugin: 'presentationLetterTemplate'
                });
            }
        }
    }
}
PresentationLetterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PresentationLetterService_Factory() { return new PresentationLetterService(i0.ɵɵinject(i1.SettingService)); }, token: PresentationLetterService, providedIn: "root" });
