/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "../../projects/simple-notification/src/lib/components/simple-notifications/simple-notifications.component.ngfactory";
import * as i4 from "../../projects/simple-notification/src/lib/components/simple-notifications/simple-notifications.component";
import * as i5 from "../../projects/simple-notification/src/lib/services/notifications.service";
import * as i6 from "@ngx-translate/core";
import * as i7 from "./app.component";
import * as i8 from "@angular/common/http";
import * as i9 from "./core/service/ws.service";
import * as i10 from "./core/service/check-for-update.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "w-100 p-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(2, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "simple-notifications", [], null, null, null, i3.View_SimpleNotificationsComponent_0, i3.RenderType_SimpleNotificationsComponent)), i1.ɵdid(4, 245760, null, 0, i4.SimpleNotificationsComponent, [i5.NotificationsService, i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "error-connect"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { _ck(_v, 2, 0); _ck(_v, 4, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showErrorConnect; _ck(_v, 5, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("\u0421\u043E\u0435\u0434\u0438\u043D\u0435\u043D\u0438\u044F \u0441 \u0441\u0435\u0440\u0432\u0435\u0440\u043E\u043C \u0440\u0430\u0437\u043E\u0440\u0432\u0430\u043D\u043E.")); _ck(_v, 7, 0, currVal_1); }); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], [[8, "className", 0]], null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i7.AppComponent, [i8.HttpClient, i9.WsService, i10.CheckForUpdateService, i6.TranslateService, i2.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).appClass; _ck(_v, 0, 0, currVal_0); }); }
var AppComponentNgFactory = i1.ɵccf("app-root", i7.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
